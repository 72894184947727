import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "../../index.css";
import "../../App.css";
import "react-date-range/dist/theme/default.css"; // theme css file
import DashboardHeader from "../Dashboard/DashboardHeader";

import DashboardSidebar from "../Dashboard/DashboardSidebar";

import axios from "axios";
import Card from "../subcomponents/Card";
import CardTemp from "../subcomponents/CardTemp";
import Select from "react-tailwindcss-select";

import { useLocation } from "react-router-dom";
// Import Preline
import "preline/preline";
import NoLink from "./NoLink";
import { DateRangePicker } from "react-date-range";
import DashboardFooter from "../Dashboard/DashboardFooter";
import axiosInstance from "../../instances/axiosInstance";
import { useCallback } from "react";
import debounce from "lodash/debounce";
import SmallLoader from "../subcomponents/SmallLoader";

// Ensure Preline methods are available globally

const Link = () => {
  const [loadingLinks, setLoadingLinks] = useState(false);
  const [value, setValue] = useState([]);
  const [recordsFound, setRecordsFound] = useState(true);

  localStorage.setItem("activeLink", "/links");
  const location = useLocation();

  const [idx, setIdx] = useState(0);
  const [totalFilteredRecords, setTotalFilteredRecords] = useState("");

  const [DOMAINS, SETDOMAINS] = useState([]);
  const [domainArray, setDomainArray] = useState(null);

  const [checkedState, setCheckedState] = useState([]);

  const [domainCount, setDomainCount] = useState(null);
  const [links, setLinks] = useState([]);
  const [itemsPeridx, setItemsPeridx] = useState(10);
  const [loading, setLoading] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [dateFilter, setDateFilter] = useState(false);
  const [slugFilter, setSlugFilter] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [domainModal, setDomainModal] = useState(false);
  const [selectedRandomOption, setSelectedRandomOption] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [customInput, setCustomInput] = useState("");
  const [customUTM, setCustomUTM] = useState("");
  const [customType, setCustomType] = useState("");

  const prelineSelect = (e) => {
    console.log(e.target.value);
    // console.log(e.target.value);
  };
  const handleSearchInput = (e) => {
    const val = e.target.value;
    console.log(val);
    setSearchInput(e.target.value);
    if (val.length > 2) {
      const arr = selectedOptions?.map((e) => e.value);
      const getLinks = async () => {
        const tokenData = localStorage.getItem("token");
        if (!tokenData) {
          return;
        }
        let access_token;
        try {
          const data = JSON.parse(tokenData);
          access_token = data.access_token;
          if (!access_token) {
            return;
          }
        } catch (e) {
          console.log("failed to parse token data");
        }
        const requestData = {
          data: {
            index: idx, // MANDATORY
            itemsPerIndex: itemsPeridx, // MANDATORY

            fromDate: dateFilter
              ? startDate
                ? startDate.toISOString()
                : ""
              : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT
            toDate: dateFilter ? (endDate ? endDate.toISOString() : "") : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT,

            domainIds: arr ? arr : "All", // OPTIONAL ID FROM /get-domains API

            slugType: slugFilter ? slugFilter : "All", // OPTIONAL [DEFUALT VALUE - "ALL"] , [ POSSIBLE VALUES - Links with slug and Links without slug]

            search: val,
          },
        };

        const config = {
          method: "post",
          url: `/atz/links/get-links`,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        try {
          setLoadingLinks(true);
          const response = await axiosInstance(config);
          // console.log(response.data.data);
          const linkk = response.data.data.links;
          setTotalFilteredRecords(response.data.data.recordsTotal);
          if (response.status === 200) {
            setRecordsFound(true);
            setLoadingLinks(false);
          }
          console.log(response);
          setLinks(linkk);
          console.log(links);
        } catch (error) {
          setLoadingLinks(false);
          if (error.response.status === 404) {
            setRecordsFound(false);
            setTotalFilteredRecords(0);
          }
          console.log("Error response:", error.response);
          console.log("Error message:", error.message);
          // setObj({ error: true });
        }
      };
      getLinks();
    }
  };

  console.log(DOMAINS);

  useEffect(() => {
    const arr = selectedOptions?.map((e) => e.value);
    const getLinks = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        return;
      }
      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          return;
        }
      } catch (e) {
        console.log("failed to parse token data");
      }
      const requestData = {
        data: {
          index: idx, // MANDATORY
          itemsPerIndex: itemsPeridx, // MANDATORY

          fromDate: dateFilter
            ? startDate
              ? startDate.toISOString()
              : ""
            : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT
          toDate: dateFilter ? (endDate ? endDate.toISOString() : "") : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT,

          domainIds: arr ? arr : "All", // OPTIONAL ID FROM /get-domains API

          slugType: slugFilter2 ? slugFilter2 : "All", // OPTIONAL [DEFUALT VALUE - "ALL"] , [ POSSIBLE VALUES - Links with slug and Links without slug]

          search: searchInput,
        },
      };

      const config = {
        method: "post",
        url: `/atz/links/get-links`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingLinks(true);
        const response = await axiosInstance(config);
        // console.log(response.data.data);
        const linkk = response.data.data.links;
        setTotalFilteredRecords(response.data.data.recordsTotal);
        console.log(response);
        setLinks(linkk);
        console.log(links);
        if (response.status === 200) {
          setLoadingLinks(false);
          setRecordsFound(true);
        }
      } catch (error) {
        setLoadingLinks(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getLinks();
  }, [idx, itemsPeridx, searchInput.length === 0 ? searchInput : null]);
  useEffect(() => {
    const getDomainCount = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        return;
      }
      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          return;
        }
      } catch (e) {
        console.log("failed to parse token data");
      }
      const requestData = {
        data: {},
      };
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/links-count`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingLinks(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log("domain count : " + JSON.stringify(data));
        setDomainCount(JSON.stringify(data));
        if (response.status == 200) {
          setLoadingLinks(false);
        }
      } catch (error) {
        setLoadingLinks(false);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomainCount();
  }, []);
  useEffect(() => {
    const getDomains = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/get-domains`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        SETDOMAINS(data);
        setAvailableOptions(data);
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomains();
  }, []);

  useEffect(() => {
    const temp = DOMAINS?.map((domain) => domain.domain);
    setDomainArray(temp);
    // setAvailableOptions(temp);
  }, [DOMAINS]);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, loadingLinks, DOMAINS]);

  console.log(value);

  const handleSlugFilter = (e) => {
    setSlugFilter(e.target.value);
  };

  const handleIdxChange = (e) => {
    setIdx(0);
    setItemsPeridx(e.target.value);
  };
  const handleApplyFilter = () => {
    setLoading(true);
    const arr = selectedOptions?.map((e) => e.value);
    setFilterLoading(true);
    const getLinks = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        return;
      }
      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          return;
        }
      } catch (e) {
        console.log("failed to parse token data");
      }
      const requestData = {
        data: {
          index: idx, // MANDATORY
          itemsPerIndex: itemsPeridx, // MANDATORY

          fromDate: dateFilter
            ? startDate
              ? startDate.toISOString()
              : ""
            : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT
          toDate: dateFilter ? (endDate ? endDate.toISOString() : "") : "", // OPTIONAL - UTC DATE TIME WITH ISO FORMAT,

          domainIds: selectedDomains ? selectedDomains : "All", // OPTIONAL ID FROM /get-domains API

          slugType: slugFilter2 ? slugFilter2 : "All", // OPTIONAL [DEFUALT VALUE - "ALL"] , [ POSSIBLE VALUES - Links with slug and Links without slug]
        },
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/get-links`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        const response = await axios(config);
        // console.log(response.data.data);
        const linkk = response.data.data.links;
        setTotalFilteredRecords(response.data.data.recordsTotal);
        // console.log(linkk);
        setLinks(linkk);
        if (response.status === 200) {
          setRecordsFound(true);
          setLoading(false);
        }
        console.log(links);
        setFilterLoading(false);
      } catch (error) {
        setFilterLoading(false);
        if (error.response.status === 404) {
          setTimeout(() => {
            setTotalFilteredRecords(0);
            setRecordsFound(false);
          }, 500);
          // alert("not found");
        }

        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      } finally {
        setFilterLoading(false);
      }
    };
    getLinks();
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState();
  const [slugFilter2, setSlugFilter2] = useState("All");
  const handleDomain = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    console.log(selectedOptions);
  };
  const handleDomain2 = (e) => {
    setSelectedOptions2(e.target.value);

    setSlugFilter2(e.target.value);
    console.log(slugFilter2);

    // console.log(selectedOptions.value);
  };

  const { startDate, endDate } = selectionRange;

  const handleSelect = (ranges) => {
    console.log(startDate.toISOString());
    console.log(endDate.toISOString());
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    setSelectionRange(ranges.selection);
  };
  const handleDateFilter = () => {
    setDateFilter(true);
  };

  const options = DOMAINS?.map((domain) => ({
    value: domain.id,
    label: domain.domain,
  }));
  // const options = [
  //   {
  //     value: "664763333b5c5f8b42a54975",
  //     label: "dev.atz.sh",
  //   },
  //   {
  //     value: "6682f3d425c59d7fa171358c",
  //     label: "dev.test.sh",
  //   },
  //   {
  //     value: "6690f4ff047d0ac633a0f5e2",
  //     label: "dev.todo.sh",
  //   },
  // ];
  const options2 = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Links without slug",
      label: "Links without slug",
    },
    {
      value: "Links with slug",
      label: "Links with slug",
    },
  ];

  const handleInputClick = () => {
    setDomainModal(!domainModal);
  };

  const handleOptionClick = (option) => {
    setSelectedRandomOption((prev) => [...prev, option]);
    setAvailableOptions((prev) => prev.filter((item) => item.id !== option.id));
    setSelectedDomains((prev) => [...prev, option.id]);
    setDomainModal(false);
    // Make the API call with the selected option's id (if needed)
    console.log("Selected Domain ID:", option.id);
  };

  const removePill = (option) => {
    setSelectedRandomOption((prev) =>
      prev.filter((item) => item.id !== option.id)
    );
    setSelectedDomains((prev) => prev.filter((id) => id !== option.id));
    setAvailableOptions((prev) => [...prev, option]);
  };

  const handleCustomType = (e) => {
    setCustomType(e.target.value);
  };
  const handleCustomUTM = (e) => {
    setCustomUTM(e.target.value);
  };
  const handleCustomValue = (e) => {
    setCustomInput(e.target.value);
  };

  return (
    <>
      {/* {filterLoading === true && <Loader />} */}
      <>
        <div
          id="hs-offcanvas-right"
          class="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-xs w-full z-[80] bg-white border-s dark:bg-neutral-800 dark:border-neutral-700"
          role="dialog"
          tabindex="-1"
          aria-labelledby="hs-offcanvas-right-label"
        >
          <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
            <h3
              id="hs-offcanvas-right-label"
              class="font-bold text-gray-800 dark:text-white"
            >
              Build Filter
            </h3>
            <button
              type="button"
              class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
              aria-label="Close"
              data-hs-overlay="#hs-offcanvas-right"
            >
              <span class="sr-only">Close</span>
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18"></path>
                <path d="m6 6 12 12"></path>
              </svg>
            </button>
          </div>
          <div className="p-4 space-y-4">
            <div className="min-h-20 border rounded-md ">
              <div className="border-b p-2 text-xs text-neutral-700 font-semibold">
                CONDITIONS (UPTO 3)
              </div>
              <div className="p-4 text-xs space-y-4">
                <div className="space-y-1">
                  <div>UTM</div>
                  <select
                    className="min-w-full border-neutral-200 dark:border-neutral-700 focus:border-neutral-200 focus:ring-0 dark:focus:border-neutral-700 text-xs rounded-md "
                    onChange={handleCustomUTM}
                  >
                    <option selected disabled>
                      SELECT UTM
                    </option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
                {customUTM.length > 0 && (
                  <div className="space-y-1">
                    <div>MATCH TYPE</div>
                    <select
                      className="min-w-full border-neutral-200 dark:border-neutral-700 focus:border-neutral-200 focus:ring-0 dark:focus:border-neutral-700 text-xs rounded-md"
                      onChange={handleCustomType}
                    >
                      <option selected disabled>
                        SELECT TYPE
                      </option>
                      <option>contains</option>
                      <option>exactly matches</option>
                      <option>begins with</option>
                      <option>ends with</option>
                    </select>
                  </div>
                )}
                {customType.length > 0 && (
                  <div className="space-y-1">
                    <div>VALUE</div>
                    <input
                      className="min-w-full border-neutral-200 dark:border-neutral-700 focus:border-neutral-200 focus:ring-0 dark:focus:border-neutral-700 text-xs rounded-md"
                      placeholder="Enter dimension value"
                      value={customInput}
                      onChange={handleCustomValue}
                    />
                  </div>
                )}
              </div>
            </div>
            {customUTM.length > 0 &&
              customType.length > 0 &&
              customInput.length > 0 && (
                <div className="min-h-20 border rounded-md">
                  <div className="border-b p-2 text-xs text-neutral-700 font-semibold">
                    SUMMARY
                  </div>
                  <div className="p-4 text-xs space-y-4">
                    <div className="space-y-1">
                      <div className="text-[#019ca2] font-semibold">
                        {customUTM +
                          " " +
                          customType +
                          " '" +
                          customInput +
                          "' "}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <button
              className="p-2 text-center min-w-full text-xs font-semibold border border-[#019ca2] hover:bg-[#019ca2] hover:text-white text-[#019ca2] rounded-md disabled:bg-neutral-600 disabled:border-neutral-600 disabled:text-white"
              disabled={
                customInput.length < 1 ||
                customType.length < 1 ||
                customUTM.length < 1
              }
            >
              Apply
            </button>
          </div>
        </div>
      </>

      <div className="flex flex-col h-screen bg-neutral-100 dark:bg-neutral-950">
        <DashboardHeader />
        <DashboardSidebar />
        <DashboardFooter />
        {domainCount > 0 && (
          <main id="content px-2" class="lg:ps-[260px] pt-[59px]">
            <ol class="md:hidden py-3 px-2 sm:px-5 flex items-center whitespace-nowrap">
              <li class="flex items-center text-sm text-gray-600 dark:text-neutral-500">
                Links
                <svg
                  class="flex-shrink-0 mx-1 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </li>

              <li
                class="text-sm font-semibold text-gray-800 truncate dark:text-neutral-200"
                aria-current="page"
              >
                Overview
              </li>
            </ol>
            <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5 ">
              {/* <!-- Page Header --> */}
              <div
                class="flex justify-between items-center gap-x-5 p-3"
                id="btn"
              >
                <h2 class="inline-block text-4xl font-bold text-gray-800 dark:text-neutral-200 mb-4 sm:mb-0">
                  Links ({domainCount})
                </h2>
                <button
                  className="sm:hidden py-2 px-4 bg-[#019ca2] text-white rounded-lg font-semibold hover:bg-teal-800 transition-all -mt-3"
                  onClick={() => {
                    window.location.href = "/create-link";
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="1"
                      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
                <button
                  className="hidden sm:block py-2 px-16 bg-[#019ca2] text-white rounded-md font-semibold hover:bg-teal-800 transition-all"
                  onClick={() => {
                    window.location.href = "/create-link";
                  }}
                >
                  + Create Link
                </button>
              </div>
            </div>
            <div className="p-2  sm:p-5">
              <div className="py-4">
                {
                  <>
                    <div class="p-5 space-y-4 flex flex-col bg-white dark:bg-neutral-800 xl:bg-white xl:border xl:border-gray-200 xl:shadow-sm xl:rounded-xl dark:xl:bg-neutral-800 dark:xl:border-neutral-700">
                      {/* <!-- Header --> */}
                      <div class="flex justify-between items-center gap-x-2">
                        <h2 class="font-semibold text-gray-800 dark:text-neutral-200">
                          Showing {totalFilteredRecords} filtered records list
                        </h2>

                        {/* <!-- Button Group --> */}

                        {/* <!-- End Button Group --> */}
                      </div>

                      {/* <!-- End Header --> */}
                      <div class="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5">
                        <div>
                          {/* <!-- Search Input --> */}
                          <div class="relative">
                            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                              <svg
                                class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <circle cx="11" cy="11" r="8" />
                                <path d="m21 21-4.3-4.3" />
                              </svg>
                            </div>
                            <input
                              type="text"
                              value={searchInput}
                              onChange={handleSearchInput}
                              class="input-style py-[7px] px-3 ps-10 block w-full bg-inherit border border-neutral-200
                              dark:border-neutral-700 focus:border-neutral-200
                              dark:focus:border-neutral-700
                              focus:ring-0 rounded-lg text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:border-neutral-400"
                              placeholder="Search links with titles"
                            />
                          </div>
                          {/* <!-- End Search Input --> */}
                        </div>
                        {/* <!-- End Col --> */}

                        <div class="flex md:justify-end items-center gap-x-2">
                          {/* <!-- Import / Export Dropdown --> */}
                          <div class="hs-dropdown [--auto-close:true] relative inline-flex">
                            {/* <!-- Import / Export Button --> */}

                            {/* <!-- End Import / Export Button --> */}

                            {/* <!-- Dropdown --> */}
                            <div
                              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
                              aria-labelledby="hs-pro-dptied"
                            >
                              <div class="p-1">
                                <button
                                  type="button"
                                  class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                  data-hs-overlay="#hs-pro-dicm"
                                >
                                  <svg
                                    class="flex-shrink-0 mt-0.5 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                    <polyline points="7 10 12 15 17 10" />
                                    <line x1="12" x2="12" y1="15" y2="3" />
                                  </svg>
                                  Import contacts
                                </button>
                                <button
                                  type="button"
                                  class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 "
                                  data-hs-overlay="#hs-pro-decm"
                                >
                                  <svg
                                    class="flex-shrink-0 mt-0.5 size-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                    <polyline points="17 8 12 3 7 8" />
                                    <line x1="12" x2="12" y1="3" y2="15" />
                                  </svg>
                                  Export contacts
                                </button>
                              </div>
                            </div>
                            {/* <!-- End Dropdown --> */}
                          </div>
                          {/* <!-- End Import / Export Dropdown --> */}

                          {/* <!-- Download Dropdown --> */}
                          <button
                            type="button"
                            class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="hs-offcanvas-right"
                            data-hs-overlay="#hs-offcanvas-right"
                          >
                            <svg
                              class="flex-shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <line x1="21" x2="14" y1="4" y2="4" />
                              <line x1="10" x2="3" y1="4" y2="4" />
                              <line x1="21" x2="12" y1="12" y2="12" />
                              <line x1="8" x2="3" y1="12" y2="12" />
                              <line x1="21" x2="16" y1="20" y2="20" />
                              <line x1="12" x2="3" y1="20" y2="20" />
                              <line x1="14" x2="14" y1="2" y2="6" />
                              <line x1="8" x2="8" y1="10" y2="14" />
                              <line x1="16" x2="16" y1="18" y2="22" />
                            </svg>
                            Custom Filter
                          </button>
                          <button
                            type="button"
                            class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                            data-hs-overlay="#hs-overlay-right"
                          >
                            <svg
                              class="flex-shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <line x1="21" x2="14" y1="4" y2="4" />
                              <line x1="10" x2="3" y1="4" y2="4" />
                              <line x1="21" x2="12" y1="12" y2="12" />
                              <line x1="8" x2="3" y1="12" y2="12" />
                              <line x1="21" x2="16" y1="20" y2="20" />
                              <line x1="12" x2="3" y1="20" y2="20" />
                              <line x1="14" x2="14" y1="2" y2="6" />
                              <line x1="8" x2="8" y1="10" y2="14" />
                              <line x1="16" x2="16" y1="18" y2="22" />
                            </svg>
                            Filter
                            <span class="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full dark:bg-neutral-500">
                              3
                            </span>
                          </button>

                          <div
                            id="hs-overlay-right"
                            class="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform overflow-scroll h-full w-full sm:w-auto z-[80] bg-white border-s dark:bg-neutral-800 dark:border-neutral-700"
                            tabindex="-1"
                          >
                            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                              <h3 class="font-bold text-gray-800 dark:text-white">
                                Filters
                              </h3>
                              <button
                                type="button"
                                class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                                data-hs-overlay="#hs-overlay-right"
                              >
                                <span class="sr-only">Close modal</span>
                                <svg
                                  class="flex-shrink-0 size-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="M18 6 6 18"></path>
                                  <path d="m6 6 12 12"></path>
                                </svg>
                              </button>
                            </div>
                            <div class="p-4 dark:text-white">
                              <div className="space-y-2">
                                <div className="border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                                  <div className="p-4 relative">
                                    <div className="p-2">Domains</div>
                                    <input
                                      className="min-w-full overflow-auto rounded-md border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 focus:border-gray-200 dark:focus:border-neutral-700 focus:ring-0 dark:text-white dark:placeholder:text-neutral-300 cursor-pointer"
                                      placeholder="Select domain"
                                      onClick={handleInputClick}
                                    />
                                    {domainModal && (
                                      <div className="mt-1 flex flex-1 flex-col rounded-md border border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 z-[100]">
                                        {availableOptions.map((option) => (
                                          <div
                                            key={option.id}
                                            className="min-w-full border-b p-2 hover:bg-[#019ca2] dark:border-neutral-700 hover:text-white cursor-pointer"
                                            onClick={() =>
                                              handleOptionClick(option)
                                            }
                                          >
                                            {option.domain}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {/* Pills */}
                                    <div className="relative py-2 dark:text-neutral-400 z-[80]">
                                      <div className="flex flex-wrap gap-x-2 gap-y-2">
                                        {selectedRandomOption.map((option) => (
                                          <div
                                            key={option.id}
                                            className="dark:bg-neutral-900 border border-gray-300 dark:border-neutral-700 rounded-full px-4 p-2 cursor-pointer gap-x-2 flex"
                                            onClick={() => removePill(option)}
                                          >
                                            <div>{option.domain}</div>
                                            <div>x</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    {/* Pills END */}
                                  </div>
                                </div>
                                <div className="p-4 border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                                  <span>Slug | Backhalf</span>
                                  <div className="py-3 text-gray-400">
                                    <select
                                      className="min-w-full rounded-md border-gray-200 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 focus:border-gray-200 dark:focus:border-neutral-700 focus:ring-0"
                                      placeholder="Select slug type"
                                      onChange={handleDomain2}
                                    >
                                      <option
                                        value="All"
                                        className="cursor-pointer"
                                      >
                                        All
                                      </option>
                                      <option
                                        value="Links without slug"
                                        className="cursor-pointer"
                                      >
                                        Links without slug
                                      </option>
                                      <option
                                        value="Links with slug"
                                        className="cursor-pointer"
                                      >
                                        Links with slug
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="p-4 pb-0 flex flex-col  border rounded-xl font-semibold text-xs border-gray-200 dark:border-neutral-600">
                                  <span className="flex">Date</span>
                                  <div
                                    className="flex py-2 text-black overflow-auto"
                                    onClick={handleDateFilter}
                                  >
                                    <DateRangePicker
                                      initialFocusedRange={null}
                                      color="#019ca2"
                                      rangeColors={"019ca2"}
                                      ranges={[selectionRange]}
                                      onChange={handleSelect}
                                    />
                                  </div>
                                </div>
                                <div className="p-4 font-semibold text-xs flex flex-1 gap-x-6">
                                  <div className="flex flex-1">
                                    <button
                                      data-hs-overlay="#hs-overlay-right"
                                      className="p-2 border w-full dark:border-neutral-600 border-gray-200 rounded-md hover:bg-neutral-300 dark:hover:bg-neutral-700"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="flex flex-1">
                                    <button
                                      data-hs-overlay="#hs-overlay-right"
                                      className="p-2 bg-[#019ca2] w-full text-white rounded-md hover:bg-[#38afb4] "
                                      onClick={handleApplyFilter}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- End Download Dropdown --> */}
                        </div>
                        {/* <!-- End Col --> */}
                      </div>

                      {!recordsFound && <NoLink noMatch={true} />}
                      {recordsFound && (
                        <>
                          {/* <!-- Connections Grid --> */}
                          <div class="space-y-3">
                            {/* <!-- User Connection List Card --> */}
                            {loadingLinks && (
                              <div className="flex flex-1 justify-center items-center min-h-96">
                                <SmallLoader />
                              </div>
                            )}

                            {!loadingLinks && (
                              <>
                                {links.map((link, index) => (
                                  <CardTemp
                                    link={link}
                                    setLink={setLinks}
                                    index={index}
                                    key={index}
                                  />
                                ))}
                              </>
                            )}

                            {/* <!-- Footer --> */}
                            <div class="grid grid-flow-row sm:grid-cols-2 items-center gap-y-2 sm:gap-y-0 sm:gap-x-5">
                              <p class="text-sm text-gray-800 dark:text-neutral-200">
                                <span class="font-medium">
                                  {links.length + " "}
                                </span>
                                <span class="text-gray-500 dark:text-neutral-500">
                                  results
                                </span>
                              </p>

                              {/* <!-- Pagination --> */}
                              <nav className="flex justify-end items-center gap-x-1">
                                <select
                                  data-hs-select='{
  "placeholder": "<span class=\"inline-flex items-center\"><svg class=\"flex-shrink-0 size-3.5 me-2\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polygon points=\"22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3\"/></svg> Items per page</span>",
  "toggleTag": "<button type=\"button\"></button>",
  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400",
  "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-900 dark:border-neutral-700",
  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800",
  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"></span></div>",
  "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
}'
                                  class="hidden"
                                  value={itemsPeridx}
                                  onChange={handleIdxChange}
                                >
                                  <option value="">Items per page</option>
                                  <option value="10" selected>
                                    10
                                  </option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                                <button
                                  type="button"
                                  onClick={() => setIdx(idx - 1)}
                                  disabled={idx === 0}
                                  className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                                >
                                  <svg
                                    className="flex-shrink-0 size-3.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="m15 18-6-6 6-6" />
                                  </svg>
                                  <span aria-hidden="true" className="sr-only">
                                    Previous
                                  </span>
                                </button>

                                <div className="flex items-center gap-x-1">
                                  <span className="min-h-[38px] min-w-[38px] flex justify-center items-center bg-gray-100 text-gray-800 py-2 px-3 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:text-white">
                                    {idx + 1}
                                  </span>
                                  <span className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                                    of
                                  </span>
                                  <span className="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                                    {Math.ceil(
                                      totalFilteredRecords / itemsPeridx
                                    )}
                                  </span>
                                </div>

                                <button
                                  type="button"
                                  onClick={() => {
                                    setIdx(idx + 1);
                                    document.body.scrollTop =
                                      document.documentElement.scrollTop = 0;
                                  }}
                                  disabled={
                                    idx + 1 ===
                                    Math.ceil(
                                      totalFilteredRecords / itemsPeridx
                                    )
                                  }
                                  className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-white dark:hover:bg-white/10 dark:focus:bg-neutral-700"
                                >
                                  <span aria-hidden="true" className="sr-only">
                                    Next
                                  </span>
                                  <svg
                                    className="flex-shrink-0 size-3.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="m9 18 6-6-6-6" />
                                  </svg>
                                </button>
                              </nav>
                              {/* <!-- End Pagination --> */}
                            </div>
                            {/* <!-- End Footer --> */}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                }

                {/* <!-- End Connections --> */}
              </div>
            </div>
          </main>
        )}

        {domainCount == 0 && <NoLink noLinks={true} />}

        {/* </div> */}
      </div>
    </>
  );
};

export default Link;

/**
 * All Boxes
 *
 * Domain Multi
 * Slug Single
 * Date Range
 *
 */

/**
 *
 */
