import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Toast from "../subcomponents/Toast";
import Loader from "../subcomponents/Loader";
import { useLocation } from "react-router-dom";
import "preline/preline";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const CreateLinkBody = () => {
  // const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [input, setInput] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [utm, setUTM] = useState(false);
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [isValidUTM, setIsValidUTM] = useState(null);
  const [utmL, setUTML] = useState("");
  const [messageUTM, setMessageUTM] = useState("");
  const [source, setSource] = useState("");
  const [messageSource, setMessageSource] = useState("");
  const [isValidSource, setIsValidSource] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [messageCampaign, setMessageCampaign] = useState("");
  const [isValidCampaign, setIsValidCampaign] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cnfPasswordVisible, setCnfPasswordVisible] = useState(false);

  const [content, setContent] = useState("");
  const [passwordL, setPasswordL] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const [medium, setMedium] = useState("");
  const [messageMedium, setMessageMedium] = useState("");
  const [isValidMedium, setIsValidMedium] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [slug, setSlug] = useState("");
  const [term, setTerm] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidTerm, setIsValidTerm] = useState(null);
  const [domainId, setDomainId] = useState("");
  const [slugValid, isSlugValid] = useState(true);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, isLoading] = useState(false);
  const [slugMessage, setSlugMessage] = useState("");
  const location = useLocation();
  const handleToastChange = () => {
    setToastVisible(false);
  };

  const handleDomainChange = (e) => {
    setDomainId(e.target.value);
  };

  useEffect(() => {
    setDomainId(DOMAINS && DOMAINS[0].id);
  }, [DOMAINS]);

  useEffect(() => {
    const getDomains = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/domain/get-domains`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;
        SETDOMAINS(data);
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getDomains();
  }, []);

  useEffect(() => {
    const getTitle = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          url: url, // MANDATORY
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/atz/links/website-details`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axiosInstance(config);

        if (response.data.data) {
          setIsValidTitle(true);
          setTitle(response.data.data);
        }
      } catch (error) {
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };

    if (url && isValid) {
      getTitle();
    }
  }, [url]);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);

  const resetForm = () => {
    setUrl("");
    setTitle("");
    setUTML("");
    setSource("");
    setMedium("");
    setCampaign("");
    setContent("");
    setTerm("");
    setSlug("");
    setPasswordL("");
    setConfirmPassword("");
    setUTM(false);
    setPassword(false);
    setIsValidSource(null);
    setIsValid(null);
    setIsValidTitle(null);
    setIsValidTerm(null);
    setIsValidCampaign(null);
    setIsValidConfirmPassword(null);
    setIsValidContent(null);
    setIsValidMedium(null);
    setIsValidPassword(null);
    setIsValidUTM(null);
  };

  const createLinkApi = async () => {
    isLoading(true);
    const data = {
      destinationUrl: url, // MANDATORY
      title: title, // MANDATORY

      utmEnabled: utm, // OPTIONAL - DEFAULT VALUE false
      utmId: utm ? utmL : "", // MANDATORY IF utmEnabled IS true OTHERWISE OPTIONAL
      source: utm ? source : "", // MANDATORY IF utmEnabled IS true OTHERWISE OPTIONAL
      medium: utm ? medium : "", // MANDATORY IF utmEnabled IS true OTHERWISE OPTIONAL
      campaign: utm ? campaign : "", // MANDATORY IF utmEnabled IS true OTHERWISE OPTIONAL
      term: utm ? term : "", // OPTIONAL
      content: utm ? content : "", // OPTIONAL

      passwordEnabled: password, // OPTIONAL - DEFAULT VALUE false
      password: password ? passwordL : "", // MANDATORY IF passwordEnabled IS true OTHERWISE OPTIONAL
      domainId: domainId, // MANDATORY ID RECEIVED FROM /get-domain API

      slug: slug, // OPTIONAL
    };

    console.log("data sent : " + JSON.stringify(data));

    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/create-link`,
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
      data: { data },
    };

    try {
      const createLinkResponse = await axios(config);
      isLoading(false);

      console.log(createLinkResponse.status);
      if (createLinkResponse.status === 200) {
        isSlugValid(true);
        setToastVisible(true);

        logEvent(analytics, `create_link_success`);
        resetForm();
        setTimeout(() => {
          setToastVisible(false);
        }, 5000);
      }
    } catch (e) {
      isLoading(false);
      if (e.response.status === 409) {
        console.log(e.response);
        setToastVisible(false);
        isSlugValid(false);
        setSlugMessage(e.response.data.data);
        console.log("slug exists already");
      } else if (e.response.status === 509) {
        console.log(e);
        setErrorMessage(e.response.data.data);
        document.getElementById("failureButton").click();
      } else {
        console.error(e);
      }
    }
  };

  // useEffect(() => {
  //   axios.get(`https://jsonplaceholder.typicode.com/posts`).then((res) => {
  //     console.log(res.data);
  //   });
  //   // console.log(res.data);
  // }, []);
  const handleVisiblePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleVisibleConfirmPassword = () => {
    setCnfPasswordVisible(!cnfPasswordVisible);
  };
  const handleChangeSlug = (e) => {
    const value = e.target.value;
    setSlug(value);
  };
  const validateURL = (url) => {
    // var urlR = /^(http|https):\/\/[\w.-]+(?:[:\d])(\/.)?$/;
    var urlR =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(urlR);
    return regex.test(url);
  };

  const validateTitle = (title) => {
    return title.length > 0;
  };

  const handleChangeTitle = (e) => {
    const value = e.target.value;
    setTitle(value);

    const isValid = validateTitle(value); // Validate the updated value
    setIsValidTitle(isValid);

    if (isValid) {
      setMessageTitle("");
    } else {
      // setIsValidTitle(false);
      setMessageTitle("Please provide title");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setUrl(value);
    setIsValid(validateURL(value));

    if (isValid) {
      setMessage("");
    } else {
      if (value.length < 1) setMessage("Please provide destination url");
      else {
        setMessage("Please provide valid destination url");
      }
    }
  };

  const validateUTM = (utmL) => {
    return utmL.length > 0;
  };
  const handleChangeUTM = (e) => {
    const value = e.target.value;

    setUTML(value);
    if (value.includes("&")) {
      setIsValidUTM(false);
      setMessageUTM("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateUTM(value); // Validate the updated value
      setIsValidUTM(isValid);
      if (isValid) {
        // setIsValidTitle(true);
        setMessageUTM("");
      } else {
        // setIsValidTitle(false);
        setMessageUTM("Please provide utm id");
      }
    }
  };

  const validateSource = (source) => {
    return source.length > 0;
  };
  const handleChangeSource = (e) => {
    const value = e.target.value;
    setSource(value);
    if (value.includes("&")) {
      setIsValidSource(false);
      setMessageSource("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateSource(value);
      setIsValidSource(isValid);

      if (isValid) {
        // setIsValidTitle(true);
        setMessageSource("");
      } else {
        // setIsValidTitle(false);
        setMessageSource("Please provide utm source");
      }
    }
  };
  const validateMedium = (message) => {
    return message.length > 0;
  };
  const handleChangeMedium = (e) => {
    const value = e.target.value;
    setMedium(value);
    if (value.includes("&")) {
      setIsValidMedium(false);
      setMessageMedium("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateMedium(value);

      setIsValidMedium(isValid);

      if (isValid) {
        // setIsValidTitle(true);
        setMessageMedium("");
      } else {
        // setIsValidTitle(false);
        setMessageMedium("Please provide utm medium");
      }
    }
  };

  const validateCampaign = (campaign) => {
    return campaign.length > 0;
  };
  const handleChangeCampaign = (e) => {
    const value = e.target.value;
    setCampaign(value);
    if (value.includes("&")) {
      setIsValidCampaign(false);
      setMessageCampaign("Cannot enter '&' in the utm id");
    } else {
      const isValid = validateCampaign(value);
      setIsValidCampaign(isValid);

      if (isValid) {
        setMessageCampaign("");
      } else {
        setMessageCampaign("Please provide utm campaign");
      }
    }
  };
  const [isValidContent, setIsValidContent] = useState(null);
  const handleChangeTerm = (e) => {
    const value = e.target.value;
    // const value = e.target.value;
    setTerm(value);
    if (value.includes("&")) {
      // alert("err");
      setIsValidTerm(false);
      console.log("Cannot enter '&' in utm term");
    } else {
      setIsValidTerm(null);
    }
  };
  const handleChangeContent = (e) => {
    const value = e.target.value;
    // const value = e.target.value;
    setContent(value);
    if (value.includes("&")) {
      // alert("err");
      setIsValidContent(false);
      console.log("Cannot enter '&' in utm content");
    } else {
      setIsValidContent(null);
    }
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPasswordL(value);
    console.log(passwordL);
    const valid = validatePassword(value);
    const validc = validateConfirmPassword(confirmPassword);
    setIsValidConfirmPassword(validc);
    setIsValidPassword(valid);
    if (valid || validc) {
      if (valid) {
        setPasswordMessage("");
        if (validc === false && confirmPassword.length > 0) {
          setConfirmPasswordMessage(
            "Confirm password does not match with the password"
          );
        } else if (validc === false && confirmPassword.length === 0) {
          setConfirmPasswordMessage("Please provide confirm password");
        }
      }
      if (validc) {
        setConfirmPasswordMessage("");
      }
    } else {
      if (valid && !validc) {
        if (confirmPassword.length < 1) {
          setConfirmPasswordMessage("Please provide confirm password");
        } else {
          setConfirmPasswordMessage(
            "Confirm password does not match with the password"
          );
        }
      }
      setPasswordMessage("Please provide password");
    }
  };

  const validatePassword = (password) => {
    return password.length > 0;
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    return (
      password === confirmPassword &&
      password.length > 0 &&
      confirmPassword.length > 0
    );
  };

  const handleConfirmPassword = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    const valid = validateConfirmPassword(passwordL, value);
    setIsValidConfirmPassword(valid);
    if (valid) {
      setConfirmPasswordMessage("");
    } else {
      if (value.length < 1) {
        setConfirmPasswordMessage("Please provide confirm password.");
      } else {
        setConfirmPasswordMessage(
          "Confirm password does not match with password."
        );
      }
    }
  };
  const handleSubmitPublish = () => {
    if (utm) {
      //utd id source medium campaign
      if (utmL.length < 1) {
        setIsValidUTM(false);
        setMessageUTM("Please provide utm id");
      }
      if (source.length < 1) {
        setIsValidSource(false);
        setMessageSource("Please provide utm source");
      }
      if (medium.length < 1) {
        setIsValidMedium(false);
        setMessageMedium("Please provide utm medium");
      }
      if (campaign.length < 1) {
        setIsValidCampaign(false);
        setMessageCampaign("Please provide utm campaign");
      }
    }
    if (password) {
      if (passwordL.length < 1 && confirmPassword.length < 1) {
        setIsValidPassword(false);
        setIsValidConfirmPassword(false);
        setPasswordMessage("Please provide password");
        setConfirmPasswordMessage("Please provide confirm password");
      } else {
        if (passwordL.length < 1) {
          setIsValidPassword(false);
          setPasswordMessage("Please provide password");
        } else if (confirmPassword.length < 1) {
          setIsValidConfirmPassword(false);
          setConfirmPasswordMessage("Please provide confirm password");
        }
      }
    }
    if (url.length === 0) {
      if (title.length < 1) {
        setIsValidTitle(false);
        setMessageTitle("Please provide title");
      }
      setIsValid(false);
      setMessage("Please provide destination url");
    } else {
      if (isValid) {
        setMessage("");
      } else {
        setMessage("Please provide valid destination url");
      }
    }

    if (isValid && isValidTitle) {
      if (utm || password) {
        if (utm && !password) {
          if (isValidCampaign && isValidUTM && isValidSource && isValidMedium) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else if (password && !utm) {
          if (isValidPassword && isValidConfirmPassword) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else {
          if (
            isValidCampaign &&
            isValidUTM &&
            isValidSource &&
            isValidMedium &&
            isValidPassword &&
            isValidConfirmPassword
          ) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        }
      } else {
        isLoading(false);
        createLinkApi();
      }
    } else {
    }
  };

  /**
   *
   * VALIDATIONS
   *
   * destination url -- valid
   * title -- valid
   * if(utm) --> all 4 valid
   * if(password) --> password confirm password valid
   *
   *
   *
   */

  const togglePassword = () => {
    setPassword(!password);
  };

  const toggleUTM = () => {
    setUTM(!utm);
  };

  return (
    <>
      <>
        <div
          id="hs-scale-animation-modal1"
          class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
          role="dialog"
          tabindex="-1"
          aria-labelledby="hs-scale-animation-modal1-label"
        >
          <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
            <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
              {/* <!-- Header --> */}
              <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
                <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                  Limit Exceeded
                </h3>

                {/* <!-- End Header --> */}
                <button
                  type="button"
                  class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                  aria-label="Close"
                  data-hs-overlay="#hs-scale-animation-modal1"
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              </div>
              <div
                class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
              >
                <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                  <div>
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      className="size-10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                          stroke="none"
                          fill-rule="evenodd"
                          fill="#ff0000"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="text-sm font-semibold -mb-1">
                    Link consumption exceeded
                  </div>
                  <div className="text-xs flex  text-center px-5">
                    <span>
                      Unfortunately, we cannot create your shorten link as you
                      have already consumed the links limit for this month.
                      {"\u00a0"}
                      <span
                        className="inline-flex text-[#019ca2] hover:underline cursor-pointer"
                        onClick={() =>
                          (window.location.href = "/billing/subscriptions")
                        }
                      >
                        Upgrade your plan!
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div
          id="hs-vertically-centered-modal"
          class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
          role="dialog"
          tabindex="-1"
          aria-labelledby="hs-vertically-centered-modal-label"
        >
          <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
            <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
              <div class="flex justify-end items-center py-3 px-4 ark:border-neutral-700">
                <button
                  type="button"
                  class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                  aria-label="Close"
                  data-hs-overlay="#hs-vertically-centered-modal"
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              </div>
              <div class="-mt-10 min-h-96 flex flex-col justify-center items-center space-y-4">
                <div>
                  <svg
                    version="1.1"
                    className="h-10 w-10"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 50 50"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <circle
                        style={{ fill: "#D75A4A" }}
                        cx="25"
                        cy="25"
                        r="25"
                      ></circle>{" "}
                      <polyline
                        style={{
                          fill: "none",
                          stroke: "#FFFFFF",
                          strokeWidth: "2",
                          strokeLinecap: "round",
                          strokeMiterlimit: "10",
                        }}
                        points="16,34 25,25 34,16 "
                      ></polyline>{" "}
                      <polyline
                        style={{
                          fill: "none",
                          stroke: "#FFFFFF",
                          strokeWidth: "2",
                          strokeLinecap: "round",
                          strokeMiterlimit: "10",
                        }}
                        points="16,16 25,25 34,34 "
                      ></polyline>{" "}
                    </g>
                  </svg>
                </div>
                <p class="text-gray-800 font-medium  dark:text-neutral-400">
                  {errorMessage && errorMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
      {loading && <Loader />}
      {!loading && (
        <div>
          {toastVisible && (
            <div className="flex justify-end pr-8 pt-1">
              <div className="absolute">
                <Toast update={false} onClose={handleToastChange} />
              </div>
            </div>
          )}

          <div className=" sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-4 rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}

            <div className="flex flex-col py-6 pl-4">
              {/* top section */}
              <div className="flex flex-col sm:flex-row justify-between items-center gap-x-5">
                <h2 className="inline-block text-4xl font-bold text-gray-800 dark:text-neutral-200 ">
                  Create Link
                </h2>

                <div className="justify-end items-center gap-x-2 mt-4 sm:mt-0 hidden sm:block">
                  {/* <!-- Button --> */}
                  <button
                    className="py-2 px-6 mx-2 inline-flex items-center gap-x-2 font-semibold text-sm  rounded-md  border-[1px] border-neutral-200 text-[#019ca2]  hover:bg-neutral-50 hover:text-[#019ca2] hover:border-neutral-200 hover:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-[#019ca2] dark:bg-inherit dark:border-neutral-700 dark:hover:bg-neutral-700 dark:hover:border-neutral-800 transition-all"
                    onClick={() => (window.location.href = "/links")}
                  >
                    Cancel
                  </button>
                  <button
                    className="py-2 px-6 sm:px-24 inline-flex transition-all items-center gap-x-2 font-semibold text-sm rounded-md text-white border-[1px] bg-[#019ca2] border-[#019ca2] hover:border-transparent   hover:bg-[#019da2b2] disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:bg-[#019da2b2]"
                    onClick={handleSubmitPublish}
                  >
                    Publish Link
                  </button>
                  {/* <!-- End Button --> */}
                </div>
              </div>
              {/* end top section */}

              {/* mid section */}
              <div className="flex flex-col md:flex-row justify-between border-neutral-200 rounded-md px-4 py-2 my-4">
                <div className="flex flex-col flex-1 py-2">
                  <div className="text-xl font-semibold">
                    Link Details
                    <div className="mt-5 mr-0 md:mr-1">
                      <div className="flex flex-col -space-y-1 relative">
                        <div className="relative flex-1">
                          <input
                            autoComplete="off"
                            type="text"
                            id="hs-floating-input-email1"
                            className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] dark:border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                              url.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                            } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                              isValid === false
                                ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                : isValid === true
                                ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                : "border-gray-200 dark:border-neutral-700 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                            }`}
                            placeholder="you@email.com"
                            value={url}
                            onChange={handleChange}
                          />

                          <label
                            htmlFor="hs-floating-input-emai2l"
                            className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                              url.length > 0
                                ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                : "text-[9px] peer-focus:text-[9px] "
                            } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                          >
                            DESTINATION URL
                          </label>
                          {isValid === true && (
                            <svg
                              fill="#22c55e"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="20px"
                              height="20px"
                              viewBox="0 0 335.765 335.765"
                              xmlSpace="preserve"
                              className="absolute top-1/2 right-3 transform -translate-y-1/2"
                            >
                              <g>
                                <g>
                                  <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                </g>
                              </g>
                            </svg>
                          )}

                          {isValid === false && (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute top-1/2 right-3 transform -translate-y-1/2"
                            >
                              <path
                                d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#ef4444"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                        {isValid === false && (
                          <p
                            className="text-[12px] font-light text-red-600"
                            id="hs-validation-email-error-helper"
                          >
                            {message}
                          </p>
                        )}
                        {isValid === true && (
                          <p
                            className="text-[12px] -top-1 font-light text-green-600"
                            id="hs-validation-email-success-helper"
                          ></p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 mr-0 md:mr-1">
                      {/* <EmailValidationForm label={"TITLE"} /> */}
                      <div className="flex flex-col -space-y-1 relative">
                        <div className="relative flex-1">
                          <input
                            autoComplete="off"
                            type="text"
                            id="hs-floating-input-email3"
                            className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                              title.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                            } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 focus:outline-none  ${
                              isValidTitle === false
                                ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                : isValidTitle === true
                                ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                            }`}
                            placeholder="you@email.com"
                            value={title}
                            onChange={handleChangeTitle}
                          />

                          <label
                            htmlFor="hs-floating-input-email4"
                            className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                              title.length > 0
                                ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                : "text-[9px] peer-focus:text-[9px] "
                            } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                          >
                            TITLE
                          </label>
                          {isValidTitle === true && (
                            <svg
                              fill="#22c55e"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="20px"
                              height="20px"
                              viewBox="0 0 335.765 335.765"
                              xmlSpace="preserve"
                              className="absolute top-1/2 right-3 transform -translate-y-1/2"
                            >
                              <g>
                                <g>
                                  <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                </g>
                              </g>
                            </svg>
                          )}

                          {isValidTitle === false && (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute top-1/2 right-3 transform -translate-y-1/2"
                            >
                              <path
                                d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#ef4444"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                        {isValidTitle === false && (
                          <p
                            className="text-[12px] -top-1 font-light text-red-600"
                            id="hs-validation-email-error-helper"
                          >
                            {messageTitle}
                          </p>
                        )}
                        {isValidTitle === true && (
                          <p
                            className="text-[12px] -top-1 font-light text-green-600"
                            id="hs-validation-email-success-helper"
                          ></p>
                        )}
                      </div>
                    </div>
                    <div>
                      {/* MidPart Hidden */}
                      <div className="py-2 mt-3">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            onClick={toggleUTM}
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                          />
                          <div className="absolute w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                          <span className="ms-14 text-sm font-medium text-gray-500 dark:text-gray-300">
                            Add UTMs to track web traffic in analytics tools
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className={`${utm ? `` : `hidden`}`}>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                        <div className="flex-col flex-1 -space-y-1">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email5"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                utmL.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidUTM === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidUTM === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={utmL}
                              onChange={handleChangeUTM}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                utmL.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              UTM ID
                            </label>
                            {isValidUTM === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidUTM === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidUTM === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageUTM}
                            </p>
                          )}
                          {isValidUTM === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"SOURCE"} /> */}
                        <div className="flex-col flex-1 -space-y-1 ">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email6"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                source.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidSource === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidSource === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={source}
                              onChange={handleChangeSource}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                source.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              SOURCE
                            </label>
                            {isValidSource === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidSource === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidSource === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageSource}
                            </p>
                          )}
                          {isValidSource === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"MEDIUM"} /> */}
                        <div className=" flex-col flex-1 -space-y-1 relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email7"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                medium.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidMedium === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidMedium === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={medium}
                              onChange={handleChangeMedium}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                medium.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              MEDIUM
                            </label>
                            {isValidMedium === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidMedium === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidMedium === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageMedium}
                            </p>
                          )}
                          {isValidMedium === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
                        {/* <EmailValidationForm label={"CAMPAIGN"} /> */}
                        <div className="flex flex-col -space-y-1 relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-emai8l"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                campaign.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidCampaign === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : isValidCampaign === true
                                  ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={campaign}
                              onChange={handleChangeCampaign}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                campaign.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              CAMPAIGN
                            </label>
                            {isValidCampaign === true && (
                              <svg
                                fill="#22c55e"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="20px"
                                height="20px"
                                viewBox="0 0 335.765 335.765"
                                xmlSpace="preserve"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <g>
                                  <g>
                                    <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                  </g>
                                </g>
                              </svg>
                            )}

                            {isValidCampaign === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidCampaign === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {messageCampaign}
                            </p>
                          )}
                          {isValidCampaign === true && (
                            <p
                              className="text-[12px] -top-1 font-light text-green-600"
                              id="hs-validation-email-success-helper"
                            ></p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"CONTENT"} /> */}
                        <div className="flex flex-col -space-y-1 relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email9"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                content.length > 0
                                  ? "pt-5 pb-2.5"
                                  : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none  ${
                                isValidContent === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={content}
                              onChange={handleChangeContent}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                content.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              CONTENT
                            </label>

                            {isValidContent === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidContent === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {"Cannot enter '&' in utm content"}
                            </p>
                          )}
                        </div>
                        {/* <EmailValidationForm label={"TERM"} /> */}
                        <div className="flex flex-col -space-y-1 relative">
                          <div className="relative flex-1">
                            <input
                              autoComplete="off"
                              type="text"
                              id="hs-floating-input-email"
                              className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                term.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                              } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none   ${
                                isValidTerm === false
                                  ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                  : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                              }`}
                              placeholder="you@email.com"
                              value={term}
                              onChange={handleChangeTerm}
                            />

                            <label
                              htmlFor="hs-floating-input-email"
                              className={`absolute top-0 start-0 font-bold p-1.5 px-3  h-full peer-focus:text-[10px] ${
                                term.length > 0
                                  ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                                  : "text-[9px] peer-focus:text-[9px] "
                              } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                            >
                              TERM
                            </label>

                            {isValidTerm === false && (
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                              >
                                <path
                                  d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                  stroke="#ef4444"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          {isValidTerm === false && (
                            <p
                              className="text-[12px] -top-1 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {"Cannot enter '&' in utm term"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* Bottom Part */}
                    <div className="flex flex-col md:flex-row border-neutral-200 py-2 my-4">
                      <div className="flex flex-col text-xl font-semibold w-full">
                        Ways to share
                        <div className="flex mt-5 gap-4 w-full">
                          <div className="flex flex-col sm:flex-row gap-y-4 sm:gap-y-0  w-full flex-1 gap-x-4">
                            <div className="flex-1 relative">
                              <select
                                name="links"
                                className={`peer p-3 pl-4 pb-1 pr-10 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 focus:outline-none border-gray-200 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400 appearance-none
          `}
                                onChange={handleDomainChange}
                                placeholder=""
                              >
                                {DOMAINS?.map((domain) => (
                                  <option key={domain.id} value={domain.id}>
                                    {domain.domain}
                                  </option>
                                ))}
                              </select>

                              <label
                                className={`absolute top-0 start-0 font-bold px-4 h-full peer-focus:text-[8px] text-[8px]
            truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-0 
            peer-focus:text-gray-400 dark:peer-focus:text-neutral-400
            `}
                              >
                                DOMAIN
                              </label>
                            </div>

                            <div className="flex-1 relative">
                              <input
                                autoComplete="off"
                                type="text"
                                id="12"
                                className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                  slug.length > 0
                                    ? "pt-5 pb-2.5"
                                    : "pt-4 pb-2.5"
                                } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 focus:outline-none ${
                                  slugValid === false
                                    ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                    : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                }`}
                                placeholder="you@email.com"
                                value={slug}
                                onChange={handleChangeSlug}
                              />

                              <label
                                className={`absolute top-0 start-0 font-bold p-1.5 px-3 h-full peer-focus:text-[10px] ${
                                  slug.length > 0
                                    ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                    : "text-[9px] peer-focus:text-[9px]"
                                } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
                                  peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                              >
                                SLUG | BACK HALF
                              </label>
                              {slugValid === false && (
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="absolute right-3 top-[22px] transform -translate-y-1/2 "
                                >
                                  <path
                                    d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#ef4444"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}

                              {slugValid === false && (
                                <p
                                  className="text-[12px] -top-0.5 font-light text-red-600"
                                  id="hs-validation-email-error-helper"
                                >
                                  {slugMessage}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Bottom Part */}
                  </div>
                  <div className="pb-5">
                    <button
                      onClick={() =>
                        (window.location.href = "/create-new-domain")
                      }
                      className="flex text-center  items-center text-xs dark:text-neutral-300 text-neutral-700 font-bold -my-3 hover:text-[#019ca2]"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.5"
                          d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                        />
                        <path
                          d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      {"\u00A0"}
                      {"\u00A0"}New Domain
                    </button>
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="py-1 sm:ml-8">
                    {(utmL.length > 0 ||
                      source.length > 0 ||
                      medium.length > 0 ||
                      campaign.length > 0 ||
                      content.length > 0 ||
                      term.length > 0) && (
                      <div className="border rounded-xl p-2 pt-4 px-4 mb-7 dark:border-neutral-700 border-gray-200">
                        <span className="font-semibold text-xl">Preview</span>
                        <div className="max-w-72 break-words text-sm font-light dark:text-neutral-300 dark:border-neutral-700 text-neutral-700  py-3">
                          <div className="">
                            <div className="flex absolute">
                              <span class="animate-ping absolute inline-flex size-2  rounded-full bg-green-400 opacity-75 dark:bg-green-400"></span>
                              <span class="relative min-w-[8px] min-h-[8px] inline-flex justify-center items-center text-[10px] bg-green-400 text-white rounded-full px-1"></span>
                            </div>
                          </div>
                          <div className="-my-2">
                            <span className="pl-4">Link Preview with UTM</span>
                            <div className="pl-4">
                              <span>
                                {utmL.length > 0 ? `?utm_id=${utmL}` : ``}
                                {source.length > 0
                                  ? `?utm_source=${source}`
                                  : ``}

                                {medium.length > 0
                                  ? `?utm_medium=${medium}`
                                  : ``}

                                {campaign.length > 0
                                  ? `?utm_campaign=${campaign}`
                                  : ``}

                                {content.length > 0
                                  ? `?utm_content=${content}`
                                  : ``}

                                {term.length > 0 ? `?utm_term=${term}` : ``}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="border border-gray-200 rounded-xl p-2 pt-4  px-4 dark:border-neutral-700">
                      <div className="items-end text-xl font-semibold">
                        Protect this link with Password
                        <div className="py-2 mt-3">
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              onClick={togglePassword}
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                            />
                            <div className="absolute md:relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#019ca2] dark:peer-focus:ring-[#019ca2] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#019ca2]"></div>
                            <span className="sm:ms-3 ms-16  text-sm font-medium text-gray-500 dark:text-gray-300">
                              Enable Password Protection
                            </span>
                          </label>
                        </div>
                      </div>

                      <div
                        className={`${
                          password
                            ? `flex flex-col mt-2 pb-2 border-neutral-200  dark:border-neutral-600`
                            : `hidden`
                        }`}
                      >
                        <div className="my-3 font-semibold">
                          <div className="flex flex-col space-y-0.5 relative">
                            <div className="relative flex-1">
                              <input
                                type={`${
                                  passwordVisible ? `text` : `password`
                                }`}
                                id="hs-floating-input-password0"
                                className={`peer p-3 pr-[72px] block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                  passwordL.length > 0
                                    ? "pt-5 pb-2.5"
                                    : "pt-4 pb-2.5"
                                } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none ${
                                  isValidPassword === false
                                    ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                    : isValidPassword === true
                                    ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                    : "border-gray-200 dark:border-neutral-700 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                }`}
                                placeholder="Password"
                                value={passwordL}
                                onChange={handleChangePassword}
                              />
                              <label
                                htmlFor="hs-floating-input-password"
                                className={`absolute top-0 start-0 font-bold p-4 px-3 h-full peer-focus:text-[10px] ${
                                  passwordL.length > 0
                                    ? "pt-4 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                    : "text-[9px] peer-focus:text-[9px]"
                                } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                              >
                                PASSWORD
                              </label>
                              {passwordL.length > 0 && (
                                <button
                                  onClick={handleVisiblePassword}
                                  className="absolute top-1/2 right-10 transform -translate-y-1/2 text-neutral-800 dark:text-neutral-100"
                                >
                                  {!passwordVisible && (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                  {passwordVisible && (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </button>
                              )}

                              {isValidPassword === true && (
                                <svg
                                  fill="#22c55e"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 335.765 335.765"
                                  xmlSpace="preserve"
                                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                >
                                  <g>
                                    <g>
                                      <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                    </g>
                                  </g>
                                </svg>
                              )}

                              {isValidPassword === false && (
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                >
                                  <path
                                    d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#ef4444"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </div>
                            {isValidPassword === false && (
                              <p
                                className="text-[12px] font-light text-red-600"
                                id="hs-validation-password-error-helper"
                              >
                                {passwordMessage}
                              </p>
                            )}
                            {isValidPassword === true && (
                              <p
                                className="text-[12px] font-light text-green-600"
                                id="hs-validation-password-success-helper"
                              ></p>
                            )}
                          </div>
                        </div>
                        <div className="my-3 font-semibold">
                          <div className="flex flex-col space-y-0.5 relative">
                            <div className="relative flex-1">
                              <input
                                type={`${
                                  cnfPasswordVisible ? `text` : `password`
                                }`}
                                id="hs-floating-input-confirm-password11"
                                className={`peer p-3 pr-[72px] block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                                  confirmPassword.length > 0
                                    ? "pt-5 pb-2.5"
                                    : "pt-4 pb-2.5"
                                } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  focus:outline-none ${
                                  isValidConfirmPassword === false
                                    ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                    : isValidConfirmPassword === true
                                    ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                                    : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                                }`}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={handleConfirmPassword}
                              />
                              <label
                                htmlFor="hs-floating-input-confirm-password"
                                className={`absolute top-0 start-0 font-bold p-4 px-3 h-full peer-focus:text-[10px] ${
                                  confirmPassword.length > 0
                                    ? "pt-4 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                    : "text-[9px] peer-focus:text-[9px]"
                                } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                              >
                                CONFIRM PASSWORD
                              </label>

                              {confirmPassword.length > 0 && (
                                <button
                                  onClick={handleVisibleConfirmPassword}
                                  className="absolute top-1/2 right-10 transform -translate-y-1/2 text-neutral-800 dark:text-neutral-100"
                                >
                                  {!cnfPasswordVisible && (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                  {cnfPasswordVisible && (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </button>
                              )}

                              {isValidConfirmPassword === true && (
                                <svg
                                  fill="#22c55e"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 335.765 335.765"
                                  xmlSpace="preserve"
                                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                >
                                  <g>
                                    <g>
                                      <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                                    </g>
                                  </g>
                                </svg>
                              )}
                              {isValidConfirmPassword === false && (
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                                >
                                  <path
                                    d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#ef4444"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </div>
                            {isValidConfirmPassword === false && (
                              <p
                                className="text-[12px]  font-light text-red-600"
                                id="hs-validation-password-error-helper"
                              >
                                {confirmPasswordMessage}
                              </p>
                            )}
                            {isValidConfirmPassword === true && (
                              <p
                                className="text-[12px] font-light text-green-600"
                                id="hs-validation-password-success-helper"
                              ></p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end mid section */}
            </div>

            <div className="flex flex-col sm:flex-row justify-between items-center gap-x-5">
              <div className="flex items-center gap-x-2 -mt-10 mb-4 md:hidden ">
                {/* <!-- Button --> */}
                <button
                  className="py-3 px-6 inline-flex items-center gap-x-2 font-bold text-sm  rounded-md  bg-white border-[1px] border-[#019ca2] text-[#019ca2] hover:text-[#019ca2] hover:bg-red-600 hover:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-[#019ca2] dark:bg-inherit dark:border-neutral-700 dark:hover:bg-neutral-700 dark:hover:border-neutral-800 transition-all"
                  onClick={() => (window.location.href = "/links")}
                >
                  Cancel
                </button>
                <button
                  className="py-3 px-8 sm:px-24 inline-flex items-center gap-x-2 font-bold text-sm rounded-md text-white border-[1px] bg-[#019ca2] border-[#019ca2] hover:border-transparent   hover:bg-[#019da2b2] disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:bg-[#019da2b2]"
                  onClick={handleSubmitPublish}
                >
                  Publish Link
                </button>
                {/* <!-- End Button --> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateLinkBody;
