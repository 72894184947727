import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import CircularProgressBar from "../subcomponents/CircularProgressBar";
import PlanDetailsCard from "../AiCredits/PlanDetailsCard";
import UsageChart from "../subcomponents/Charts/UsageChart";
import TopupPlanCard from "../AiCredits/TopupPlanCard";
import Loader from "../subcomponents/Loader";
import SmallLoader from "../subcomponents/SmallLoader";
import DashboardFooter from "../Dashboard/DashboardFooter";
const Overview = () => {
  const [planDetails, setPlanDetails] = useState({});
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [progress, setProgress] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [loadingCreds, setLoadingCreds] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(false);
  const [planSelected, setPlanSelected] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const fetchPackages = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/ai-credits-packages`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {},
      };
      try {
        const response = await axios(config);
        console.log(response.data);
        setPackageData(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPackages();
  }, []);
  useEffect(() => {
    const planDetails = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/active-subscriptions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {},
      };
      try {
        setLoadingSubs(true);
        setLoadingCreds(true);
        const response = await axios(config);
        console.log(response.data);
        setPlanDetails(response.data.data);
        setProgress(
          (planDetails?.utilizedAiCredits / planDetails?.totalAiCredits) * 100
        );
        if (response.status === 200) {
          setLoadingSubs(false);
          setLoadingCreds(false);
        }
      } catch (e) {
        setLoadingSubs(false);
        setLoadingCreds(false);
        console.log(e);
      }
    };

    planDetails();
  }, []);
  const generateTxnId = async (res) => {
    const tokenData = localStorage.getItem("token");

    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }
    const requestData = {
      data: {
        razorpayPaymentId: res.razorpay_payment_id,
        razorpayOrderId: res.razorpay_order_id,
        razorpaySignature: res.razorpay_signature,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/pay-for-ai-credits`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    try {
      const response = await axios(config);
      console.log(response.data);
      if (response.status === 200) {
        setLoadingPayment(false);
        document.getElementById("successButton").click();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (e) {
      setLoadingPayment(false);
      document.getElementById("failureButton").click();
    }
  };
  const generateOrderId = async () => {
    const tokenData = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }
    const requestData = {
      data: {
        packageId: selectedPlan && selectedPlan.id,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/order`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    try {
      setLoadingPayment(true);
      const response = await axios(config);
      console.log(response.data);
      console.log("passedData : ", selectedPlan.amount);
      if (response.status === 200) {
        setLoadingPayment(false);
        console.log(
          response.data.data.id,
          `${process.env.REACT_APP_ATZ_RAZORPAY_API_KEY}`
        );
        const options = {
          key: process.env.REACT_APP_ATZ_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
          amount: selectedPlan.amount * 100, // Amount is in currency subunits. Default currency is INR.
          currency: selectedPlan.currency,
          name: "Nidavellir Software Private Limited", // your business name
          description: selectedPlan.name,
          image: `https://www.google.com/s2/favicons?sz=64&domain_url=https://nidavellirs.com/`,
          order_id: response.data.data.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            setLoadingPayment(true);
            generateTxnId(response);
            // alert("Payment ID: " + response.razorpay_payment_id);
            // alert("Order ID: " + response.razorpay_order_id);
            // alert("Signature: " + response.razorpay_signature);
          },
          prefill: {
            name: user.name, // your customer's name
            email: user.email,
            contact: "6266492943", // Provide the customer's phone number
          },
          theme: {
            color: "#019ca2",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log("payment failed");
        });

        rzp1.open();
      }
    } catch (e) {
      if (e.response.status === 400) {
        setLoadingPayment(false);
        document.getElementById("failureButton").click();
      }
      console.log(e);
    }
  };
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, loadingPayment, planDetails]);

  const handleRadio = (plan) => {
    setPlanSelected(true);
    setSelectedPlan(plan);
  };
  return (
    <>
      <div
        id="hs-scale-animation-modal"
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Get AI credits now!
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="max-h-[400px] overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="p-2 my-2 ">
                <div class="p-4 space-y-6">
                  <nav class="p-1 flex  bg-gray-100 rounded-xl dark:bg-neutral-900/50">
                    <div class=" py-2 px-3 inline-flex justify-center items-center gap-x-2 w-full text-sm font-medium text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-neutral-200 dark:focus:text-neutral-500">
                      Choose your plan
                    </div>
                  </nav>
                  {/* <!-- End Nav Tab --> */}

                  <div className="space-y-4 max-h-[450px]">
                    {packageData &&
                      packageData?.data?.map((p) => (
                        <TopupPlanCard
                          data={p}
                          plan={p.name}
                          isSelected={
                            selectedPlan && selectedPlan.name == p.name
                          }
                          onSelect={handleRadio}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <button
                type="button"
                class="py-3 px-4 w-full inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#438688] disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-[#019ca2]"
                data-hs-overlay="#hs-scale-animation-modal"
                onClick={generateOrderId}
                disabled={!planSelected}
              >
                Make payment of ${selectedPlan && selectedPlan.amount}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-pro-dminvm"
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto [--close-when-click-inside:true] pointer-events-none"
      >
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="relative w-full max-h-full overflow-hidden flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-800">
            {/* <!-- Close Button --> */}
            <div class="absolute top-2 end-4 z-10">
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                data-hs-overlay="#hs-pro-dminvm"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            {/* <!-- End Close Button --> */}

            {/* <!-- SVG Background Element --> */}
            <figure>
              <svg
                preserveAspectRatio="none"
                width="576"
                height="120"
                viewBox="0 0 576 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_666_273469)">
                  <rect width="576" height="120" fill="#B2E7FE" />
                  <rect
                    x="289.678"
                    y="-90.3"
                    width="102.634"
                    height="391.586"
                    transform="rotate(59.5798 289.678 -90.3)"
                    fill="#FF8F5D"
                  />
                  <rect
                    x="41.3926"
                    y="-0.996094"
                    width="102.634"
                    height="209.864"
                    transform="rotate(-31.6412 41.3926 -0.996094)"
                    fill="#3ECEED"
                  />
                  <rect
                    x="66.9512"
                    y="40.4817"
                    width="102.634"
                    height="104.844"
                    transform="rotate(-31.6412 66.9512 40.4817)"
                    fill="#4C48FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_666_273469">
                    <rect width="576" height="120" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </figure>
            {/* <!-- End SVG Background Element --> */}
            {planDetails && <PlanDetailsCard planDetails={planDetails} />}

            {/* <!-- Button Group --> */}

            {/* <!-- End Button Group --> */}
          </div>
          {/* <!-- End Body --> */}

          {/* <!-- Footer --> */}

          {/* <!-- End Footer --> */}
        </div>
      </div>

      {loadingPayment && <Loader />}

      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      {!loadingPayment && (
        <main
          id="content"
          className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
        >
          <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
            <h1 className="text-3xl font-bold">Overview</h1>
            <div class="p-5 py-7 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {/* <!-- Card --> */}
                <div class="flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                  <div className="border-0 border-b-[1px] border-gray-200 dark:border-neutral-700 p-3 px-5 font-semibold ">
                    Current Subscription
                  </div>
                  {/* <!-- Body --> */}
                  {loadingSubs && (
                    <div className="min-h-48 flex justify-center items-center">
                      <SmallLoader />
                    </div>
                  )}
                  {!loadingSubs && (
                    <div class="h-full p-6">
                      <div className="flex justify-between items-center">
                        {planDetails &&
                          planDetails?.subscription?.id ==
                            "66bb045d703df4a2abd3452e" && (
                            <svg
                              class="w-[34px] h-[30px]"
                              width="34"
                              height="30"
                              viewBox="0 0 34 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="5"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-neutral-300 dark:fill-neutral-300"
                              />
                            </svg>
                          )}
                        {planDetails &&
                          planDetails?.subscription?.id ===
                            "66bb045d703df4a2abd3452f" && (
                            <svg
                              class="w-[34px] h-[30px]"
                              width="34"
                              height="30"
                              viewBox="0 0 34 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="5"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
                              />
                            </svg>
                          )}
                        {planDetails &&
                          planDetails?.subscription?.id ===
                            "66bb045d703df4a2abd34530" && (
                            <svg
                              class="w-[34px] h-[30px]"
                              width="34"
                              height="30"
                              viewBox="0 0 34 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="5"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
                              />
                              <rect
                                x="14"
                                y="5"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
                              />
                            </svg>
                          )}
                        {planDetails &&
                          planDetails?.subscription?.id ===
                            "66bb045d703df4a2abd34531" && (
                            <svg
                              class="w-[34px] h-[30px]"
                              width="34"
                              height="30"
                              viewBox="0 0 34 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="7"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
                              />
                              <rect
                                y="10"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
                              />
                              <rect
                                x="14"
                                y="10"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#019ca2] dark:fill-[#019ca2]"
                              />
                            </svg>
                          )}
                        {planDetails &&
                          planDetails?.subscription?.id ===
                            "66bb045d703df4a2abd34532" && (
                            <svg
                              class="w-[34px] h-[30px]"
                              width="34"
                              height="30"
                              viewBox="0 0 34 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
                              />
                              <rect
                                y="10"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
                              />
                              <rect
                                x="14"
                                y="10"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#019ca2] dark:fill-[#019ca2]"
                              />
                              <rect
                                x="14"
                                width="20"
                                height="20"
                                rx="10"
                                fill="currentColor"
                                class="fill-[#017a7f] dark:fill-[#017a7f]"
                              />
                            </svg>
                          )}
                        <button
                          className="text-xs text-neutral-50 dark:text-neutral-100 hover:underline inline-flex items-center gap-1.5 py-1.5 px-2 font-medium bg-[#019ca2] rounded-full "
                          data-hs-overlay="#hs-pro-dminvm"
                        >
                          {" "}
                          <svg
                            viewBox="-0.5 0 25 25"
                            fill="none"
                            className="size-5"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>{" "}
                              <path
                                d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                                fill="currentColor"
                              ></path>{" "}
                            </g>
                          </svg>
                          Plan details
                        </button>
                      </div>
                      {/* <!-- Grid --> */}
                      <div class="mt-3 grid grid-cols-2 gap-x-2">
                        <div>
                          <div class="flex items-center gap-x-2">
                            <h2 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                              {planDetails && planDetails?.subscription?.name}
                            </h2>
                          </div>

                          <p class="text-xs text-gray-500 dark:text-neutral-500 -mt-0.5">
                            {planDetails &&
                              planDetails?.subscription?.description}
                          </p>
                          <p class="mt-2 text-xs text-gray-500 dark:text-neutral-500  font-semibold italic">
                            {planDetails &&
                              moment(planDetails?.createdOn).format(
                                "MMMM DD, YYYY hh:mm A"
                              )}
                          </p>
                        </div>
                        {/* <!-- End Col --> */}

                        {/* <!-- End Col --> */}
                      </div>
                      {/* <!-- End Grid --> */}
                      {/* <!-- Progress --> */}
                      <div class="my-4">
                        <div class="flex justify-between items-center gap-x-2 mb-1">
                          <h4 class="font-medium text-gray-800 dark:text-neutral-200"></h4>
                          <p class="text-sm text-gray-500 dark:text-neutral-500"></p>
                        </div>
                        <div
                          class="flex w-full h-2.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700 hidden"
                          role="progressbar"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            class="flex flex-col justify-center overflow-hidden bg-[#019ca2] text-xs text-white text-center rounded-full whitespace-nowrap"
                            style={{
                              width: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                      {/* <!-- End Progress --> */}
                      {/*  */}
                    </div>
                  )}

                  <div
                    className="text-xs flex flex-1 justify-end items-center text-neutral-600 gap-x-1 hover:text-[#019ca2] cursor-pointer hover:underline mb-6 mr-4"
                    onClick={() => {
                      localStorage.setItem("activeTab", "one");
                      window.location.href = "/billing/transactions";
                    }}
                  >
                    Transactions
                    <svg
                      viewBox="0 0 1024 1024"
                      class="icon"
                      className="size-4"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  {/* <!-- End Body --> */}

                  {/* <!-- Footer --> */}
                  <div class="flex  border-t border-gray-200  dark:border-neutral-700 ">
                    <button
                      class="py-3 px-4 w-full inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-es-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50  focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hover:text-[#019ca2] rounded-b-xl"
                      onClick={() =>
                        (window.location.href = "/billing/subscriptions")
                      }
                    >
                      {/* className="flex-shrink-0 size-3" */}
                      <svg
                        class="flex-shrink-0 mt-0.5 size-4 "
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <path d="m16 12-4-4-4 4" />
                        <path d="M12 16V8" />
                      </svg>
                      Upgrade
                    </button>
                  </div>
                  {/* <!-- End Footer --> */}
                </div>
                <div class="flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                  <div className="border-0 border-b-[1px] border-gray-200 dark:border-neutral-700 p-3 px-5 font-semibold ">
                    Ai Credits
                  </div>
                  {/* <!-- Body --> */}
                  {loadingCreds && (
                    <div className="min-h-48 flex justify-center items-center">
                      <SmallLoader />
                    </div>
                  )}
                  {!loadingCreds && (
                    <div class="h-full p-6">
                      <div className="flex justify-between items-center">
                        <svg
                          class="w-[34px] h-[30px]"
                          width="34"
                          height="30"
                          viewBox="0 0 34 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="5"
                            width="20"
                            height="20"
                            rx="10"
                            fill="currentColor"
                            class="fill-blue-300 dark:fill-blue-600"
                          />
                          <rect
                            x="14"
                            y="5"
                            width="20"
                            height="20"
                            rx="10"
                            fill="currentColor"
                            class="fill-blue-500 dark:fill-blue-700"
                          />
                        </svg>
                      </div>

                      {/* <!-- Grid --> */}
                      <div class="mt-3 grid grid-cols-2  gap-x-2">
                        {/* <!-- End Col --> */}

                        <div class="">
                          <h2 class="text-3xl font-semibold text-gray-800 dark:text-neutral-200 inline-flex items-center gap-x-1">
                            <svg
                              className="flex-shrink-0 size-5"
                              height="24"
                              width="24"
                              version="1.1"
                              id="_x32_"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                              fill="#019ca2"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g>
                                  {" "}
                                  <path
                                    class="st0"
                                    d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                                  ></path>{" "}
                                  <path
                                    class="st0"
                                    d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                                  ></path>{" "}
                                  <path
                                    class="st0"
                                    d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                                  ></path>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                            {planDetails &&
                              planDetails?.totalAiCredits -
                                planDetails?.utilizedAiCredits}
                          </h2>

                          <p class="text-sm text-gray-500 dark:text-neutral-500">
                            AI Credit Bal.
                          </p>
                        </div>
                        <div className="place-content-end -mt-14 hidden dark:grid">
                          {planDetails && (
                            <CircularProgressBar
                              progress={
                                (planDetails?.utilizedAiCredits /
                                  planDetails?.totalAiCredits) *
                                100
                              }
                              color={"#fff"}
                              utilized={planDetails?.utilizedAiCredits}
                              total={planDetails?.totalAiCredits}
                            />
                          )}
                        </div>
                        <div className="grid place-content-end -mt-14  dark:hidden">
                          {planDetails && (
                            <CircularProgressBar
                              progress={
                                (planDetails?.utilizedAiCredits /
                                  planDetails?.totalAiCredits) *
                                100
                              }
                              color={"#000"}
                              utilized={planDetails?.utilizedAiCredits}
                              total={planDetails?.totalAiCredits}
                            />
                          )}
                        </div>
                        {/* <!-- End Col --> */}
                      </div>
                      {/* <!-- End Grid --> */}

                      {/* <!-- Progress --> */}

                      {/* <!-- End Progress --> */}
                      {/*  */}
                    </div>
                  )}
                  {/* <!-- End Body --> */}
                  <div
                    className="text-xs flex flex-1 justify-end items-center text-neutral-600 gap-x-1 hover:text-[#019ca2] cursor-pointer hover:underline mb-6 mr-5"
                    onClick={() => {
                      localStorage.setItem("activeTab", "two");
                      window.location.href = "/billing/transactions";
                    }}
                  >
                    Transactions
                    <svg
                      viewBox="0 0 1024 1024"
                      class="icon"
                      className="size-4"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                          fill="currentColor"
                        ></path>
                      </g>
                    </svg>
                  </div>

                  {/* <!-- Footer --> */}
                  <div class="flex  border-t border-gray-200  dark:border-neutral-700 ">
                    <button
                      class="py-3 px-4 w-full inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-es-xl bg-white text-gray-800 shadow-sm hover:bg-gray-50  focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hover:text-[#019ca2] rounded-b-xl"
                      aria-haspopup="dialog"
                      aria-expanded="false"
                      aria-controls="hs-scale-animation-modal"
                      data-hs-overlay="#hs-scale-animation-modal"
                    >
                      <svg
                        className="flex-shrink-0 size-3"
                        height="24"
                        width="24"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="currentColor"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <path
                              class="st0"
                              d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                            ></path>{" "}
                            <path
                              class="st0"
                              d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                            ></path>{" "}
                            <path
                              class="st0"
                              d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                            ></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                      Get credits now
                    </button>
                  </div>
                  {/* <!-- End Footer --> */}
                </div>
                {/* <!-- End Card --> */}
              </div>
              <div className="grid grid-cols-1">
                <div class="flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                  <div className="border-0 border-b-[1px] border-gray-200 dark:border-neutral-700 p-3 px-5 font-semibold ">
                    Usage
                  </div>

                  <div
                    className={`min-h-96 items-center ${
                      !loadingUsage ? `hidden` : ` flex justify-center `
                    }`}
                  >
                    {" "}
                    <SmallLoader />
                  </div>

                  <div className={`p-4 ${loadingUsage ? `hidden` : ``}`}>
                    <UsageChart setLoadingUsage={setLoadingUsage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      <div
        id="hs-scale-animation-modal2"
        class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal2-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Payment Success
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal1"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    fill="#16A34A"
                    className="size-10"
                    viewBox="0 0 24 24"
                    id="d9090658-f907-4d85-8bc1-743b70378e93"
                    data-name="Livello 1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>prime</title>
                      <path
                        id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                        data-name="done circle"
                        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  Payment Success
                </div>
                <div className="text-xs flex text-center px-5">
                  Congratulations, your payment is successfull and respective
                  benefits have been added to your account.
                </div>
                <div className="text-xs flex text-center px-5">
                  This window will automatically close and your page will be
                  reloaded.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-scale-animation-modal1"
        class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Payment Failed
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal1"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fill-rule="evenodd"
                        fill="#ff0000"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  Payment Transaction Failed
                </div>
                <div className="text-xs flex text-center px-5">
                  Unfortunately, we encountered an issue processing your
                  payment. Please review your payment method or contact support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
