import React from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
const QR = () => {
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <main id="content " class="lg:ps-[260px] pt-[59px]">
        <div className="w-full p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div class="space-y-4 flex flex-col justify-between bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 mb-3">
            <section class="bg-white dark:bg-gray-900 ">
              <div class="container md:flex-row flex flex-col justify-center items-center min-h-screen px-6 py-12 mx-auto">
                <div>
                  <img
                    src={require("../../assets/images/upcoming.png")}
                    className="flex flex-1 justify-center items-center sm:-ml-1 sm:-mb-2 h-28 w-52 sm:h-full sm:w-[550px]"
                  />

                  <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                    This page is currently under development.
                  </h1>
                  <p class="mt-4 text-gray-500 dark:text-gray-400">
                    We hope that the page you are looking for is being developed
                    and is supposed to be up and running by a week or two.
                  </p>

                  <div class="flex items-center mt-6 gap-x-3">
                    <button
                      class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                      onClick={() => window.history.back()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5 rtl:rotate-180"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                        />
                      </svg>

                      <span>Go back</span>
                    </button>

                    <button
                      class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-[#019ca2] rounded-lg shrink-0 sm:w-auto hover:bg-[#14bec4] dark:hover:bg-neutral-800 dark:bg-neutral-900"
                      onClick={() => (window.location.href = "/")}
                    >
                      Take me home
                    </button>
                  </div>
                </div>
                <div className="w-fit">
                  <img
                    src={require("../../assets/images/svg/404-illustration.png")}
                    className="md:h-[450px]"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default QR;
