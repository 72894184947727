import React from "react";

const SessionExpired = () => {
  return (
    <div className="flex min-h-screen flex-1 justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-y-2">
        <img
          src={require("../assets/images/error/expired.png")}
          className="w-44"
        />
        <div className="font-semibold">
          Your current session has been expired!
        </div>
        <div className="text-xs -mt-2">
          Please login again to use the Atz platform.
        </div>
        <button
          className="px-4 py-1.5 bg-[#019ca2] hover:bg-[#266769] rounded-md text-xs min-w-full text-white font-semibold mt-4 inline-flex justify-center items-center gap-x-1"
          onClick={() => (window.location.href = "/")}
        >
          <svg
            viewBox="0 0 1024 1024"
            className="size-3"
            fill="currentColor"
            class="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z"
                fill=""
              ></path>
            </g>
          </svg>
          Go to the login page
        </button>
      </div>
    </div>
  );
};

export default SessionExpired;
