import React, { useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "preline/preline";
const TnxDetailsCard = ({ data }) => {
  const location = useLocation();
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <tr>
        <td class="size-px whitespace-nowrap pe-4 py-2 px-2 sm:px-5">
          <div class="grow">
            <span class="text-sm font-medium text-gray-800 dark:text-neutral-200">
              {data.id}
            </span>
          </div>
        </td>
        <td class="size-px whitespace-nowrap py-2 px-2 sm:px-5">
          <span class="text-sm text-gray-600 dark:text-neutral-400">
            {data.paymentId}
          </span>
        </td>
        <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
          <span class="text-sm text-gray-600 dark:text-neutral-400">
            {data.amount}
          </span>
        </td>
        <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
          {data.status == "captured" && (
            <span class="inline-flex text-xs px-4 bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5 px-2">
              {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
              Paid
            </span>
          )}
          {data.status == "failed" && (
            <span class="inline-flex text-xs  bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-2">
              {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
              Failed
            </span>
          )}
          {data.status &&
            data.status != "captured" &&
            data.status != "failed" && (
              <span class="inline-flex text-xs  bg-gray-100 text-gray-800 rounded-full dark:bg-gray-500/10 dark:text-gray-500 gap-1.5 py-1.5 px-2">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                Pending
              </span>
            )}
        </td>
        <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
          <span class="text-sm text-gray-600 dark:text-neutral-400">
            {moment(data.createdOn).format("MMM DD, YYYY hh:mm A")}
          </span>
        </td>
        <td class="size-px whitespace-nowrap px-2 sm:px-5 py-2">
          <div class="flex gap-x-2">
            <button
              type="button"
              class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              data-hs-overlay="#hs-pro-dminvm"
            >
              View
            </button>
            <button
              type="button"
              class="size-[34px] inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            >
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" x2="12" y1="15" y2="3" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TnxDetailsCard;
