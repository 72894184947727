import "preline/preline";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useLocation } from "react-router-dom";
import countryCodes from "../../../features/countryCodes";
const CountryTable = ({
  id,
  countryData,
  setModalTitle,
  setTableViewCountry,
  setCountryIso,
}) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = countryData
    .sort((a, b) => b.count - a.count)
    .slice(startIndex, endIndex);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, currentData]);

  // Calculate the data to display on the current page

  // Calculate total pages
  const totalPages = Math.ceil(countryData.length / itemsPerPage);

  return (
    <>
      {loading && (
        <div className="flex flex-1 justify-center items-center p-10 min-h-[400px]">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-[#019ca2] dark:text-[#019ca2]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading..
            </span>
          </div>
        </div>
      )}
      {!loading && (
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                      >
                        Country Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Total Clicks
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Unique Clicks
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {currentData.map((item) => (
                      <tr
                        key={item.country}
                        className="hover:bg-gray-100 dark:hover:bg-neutral-700"
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-bold text-gray-800 dark:text-neutral-200">
                          <ReactCountryFlag
                            title={item.country}
                            countryCode={item.isoCode}
                            style={{ fontSize: 1.5 + "rem" }}
                            svg
                          />
                          {"\u00a0\u00a0\u00a0"}
                          {item.country}
                        </td>
                        <td className="font-bold px-10 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-end">
                          {item.totalCount}
                        </td>
                        <td className="font-bold px-10 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-end">
                          {item.uniqueCount}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-[#019ca2] text-end">
                          <button
                            className="hover:underline"
                            onClick={() => {
                              setModalTitle(item.country);
                              setTableViewCountry(item.country);
                              setCountryIso(item.isoCode);
                            }}
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="hs-stacked-overlays"
                            data-hs-overlay="#hs-stacked-overlays"
                          >
                            View state wise
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination buttons */}
                <div className="flex flex-1 justify-end m-2">
                  <nav
                    class="flex items-center gap-x-1"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {currentPage}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {totalPages}
                      </span>
                    </div>
                    <button
                      type="button"
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CountryTable;
