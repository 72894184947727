import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "preline/preline.js";
import Home from "./components/Landing/Home";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard/Dashboard";
import Logout from "./components/Logout";
import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";
import SetData from "./components/SetData";
import Link from "./components/Links/Link";
import CreateLink from "./components/Links/CreateLink";
import Error from "./components/Error";
import CreateNewDomain from "./components/Links/CreateNewDomain";
import AiComponent from "./components/Links/AiComponent";
import { HSStaticMethods } from "preline/preline.js";
import "preline/preline";
import GenAiComp from "./components/GenAiComp.js";
import GenAiMain from "./components/GenAi/GenAiMain.js";
import Analytics from "./components/Analytics/Analytics.js";
import Gallery from "./components/Gallery/Gallery.js";
import { Upcoming } from "@mui/icons-material";
import QR from "./components/QR/QR.js";
import Links from "./components/Services/Links.js";
import BrandedDomains from "./components/Services/BrandedDomains.js";
import AtzAi from "./components/Services/AtzAi.js";
import Analytics1 from "./components/Services/Analytics.js";
import AiCredits from "./components/AiCredits/AiCredits.js";
import Overview from "./components/Billing/Overview.js";
import Subscriptions from "./components/Billing/Subscriptions.js";
import Transactions from "./components/Billing/Transactions.js";
import Domain from "./components/Domains/Domain.js";
import DomainSuccess from "./components/Domains/DomainSuccess.js";
import Qr from "./components/Services/Qr.js";
import GalleryService from "./components/Services/Gallery.js";
import axios from "axios";
import SessionExpired from "./components/SessionExpired.js";

window.HSStaticMethods = window.HSStaticMethods || {};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={<Home />} />

        <Route path="/callback" element={<Callback />} />

        <Route
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />

        <Route path="/links" element={<PrivateRoute element={Link} />} />
        <Route
          path="/create-new-domain"
          element={<PrivateRoute element={CreateNewDomain} />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/links-details"
          element={<PrivateRoute element={CreateNewDomain} />}
        />
        {/* <Route path="/links-details" element={<CreateNewDomain />} /> */}

        <Route
          path="/links-ai"
          element={<PrivateRoute element={AiComponent} />}
        />
        <Route path="/gen-ai" element={<PrivateRoute element={GenAiComp} />} />
        <Route path="/atz-ai" element={<PrivateRoute element={GenAiMain} />} />
        <Route
          path="/billing/ai-credits"
          element={<PrivateRoute element={AiCredits} />}
        />
        <Route
          path="/billing/subscriptions"
          element={<PrivateRoute element={Subscriptions} />}
        />
        <Route
          path="/billing/overview"
          element={<PrivateRoute element={Overview} />}
        />
        <Route
          path="/billing/transactions"
          element={<PrivateRoute element={Transactions} />}
        />
        <Route
          path="/analytics"
          element={<PrivateRoute element={Analytics} />}
        />
        <Route path="/domains" element={<PrivateRoute element={Domain} />} />
        <Route
          path="/domains/config"
          element={<PrivateRoute element={DomainSuccess} />}
        />
        {/* <Route path="/genai" element={<GenAiMain />} /> */}
        <Route
          path="/create-link"
          element={<PrivateRoute element={CreateLink} />}
        />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/qr-code" element={<QR />} />
        <Route path="/services/links" element={<Links />} />
        <Route path="/services/qr-code" element={<Qr />} />
        <Route path="/services/gallery" element={<GalleryService />} />
        <Route path="/services/branded-domains" element={<BrandedDomains />} />
        <Route path="/services/atz-ai" element={<AtzAi />} />
        <Route path="/services/analytics" element={<Analytics1 />} />
        <Route path="/session-expired" element={<SessionExpired />} />
      </Routes>
    </Router>
  );
}

export default App;

/*import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import Home from "./components/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "preline/preline.js";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard";
import { IStaticMethods } from "preline/preline";
import Logout from "./components/Logout";

import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";

//before any component render
//check if token object exists

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
  {
    path: "/dashboard",
    element: <PrivateRoute element={Dashboard} />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
*/
