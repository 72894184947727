import React from "react";
import moment from "moment";
const PlanDetailsCard = ({ planDetails }) => {
  return (
    <>
      <span class="-mt-8 mx-auto relative z-10 flex flex-shrink-0 justify-center items-center size-16 bg-white border border-gray-200 text-gray-700 text-xl font-medium uppercase shadow-sm rounded-full dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
        {planDetails &&
          planDetails?.subscription?.id == "66bb045d703df4a2abd3452e" && (
            <svg
              class="w-[34px] h-[30px]"
              width="34"
              height="30"
              viewBox="0 0 34 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="5"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-neutral-300 dark:fill-neutral-300"
              />
            </svg>
          )}
        {planDetails &&
          planDetails?.subscription?.id === "66bb045d703df4a2abd3452f" && (
            <svg
              class="w-[34px] h-[30px]"
              width="34"
              height="30"
              viewBox="0 0 34 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="5"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
              />
            </svg>
          )}
        {planDetails &&
          planDetails?.subscription?.id === "66bb045d703df4a2abd34530" && (
            <svg
              class="w-[34px] h-[30px]"
              width="34"
              height="30"
              viewBox="0 0 34 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="5"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
              />
              <rect
                x="14"
                y="5"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
              />
            </svg>
          )}
        {planDetails &&
          planDetails?.subscription?.id === "66bb045d703df4a2abd34531" && (
            <svg
              class="w-[34px] h-[30px]"
              width="34"
              height="30"
              viewBox="0 0 34 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="7"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
              />
              <rect
                y="10"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
              />
              <rect
                x="14"
                y="10"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#019ca2] dark:fill-[#019ca2]"
              />
            </svg>
          )}
        {planDetails &&
          planDetails?.subscription?.id === "66bb045d703df4a2abd34532" && (
            <svg
              class="w-[34px] h-[30px]"
              width="34"
              height="30"
              viewBox="0 0 34 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
              />
              <rect
                y="10"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#66c3d3] dark:fill-[#66c3d3]"
              />
              <rect
                x="14"
                y="10"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#019ca2] dark:fill-[#019ca2]"
              />
              <rect
                x="14"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
                class="fill-[#017a7f] dark:fill-[#017a7f]"
              />
            </svg>
          )}
      </span>

      {/* <!-- Body --> */}
      <div class="p-4 pt-3 space-y-8 overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
        <div class="text-center">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
            {planDetails && planDetails?.subscription?.name}
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-neutral-500">
            {planDetails && planDetails?.subscription?.description}
          </p>
        </div>

        {/* <!-- Grid --> */}
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8">
          <div>
            <span class="block text-xs uppercase text-gray-500 dark:text-neutral-500">
              Amount paid:
            </span>
            <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200">
              {planDetails &&
                planDetails?.subscription?.price?.mascot +
                  planDetails?.subscription?.price?.amount}
            </span>
          </div>
          {/* <!-- End Col --> */}

          <div>
            <span class="block text-xs uppercase text-gray-500 dark:text-neutral-500">
              Date subscribed:
            </span>
            <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200">
              {planDetails &&
                moment(planDetails?.createdOn).format("MMMM DD, YYYY")}
            </span>
          </div>
          {/* <!-- End Col --> */}

          {planDetails &&
            planDetails?.subscription?.name.includes("Freemium") && (
              <div className="space-y-1">
                <span class="ml-1 block text-xs uppercase text-gray-500 dark:text-neutral-500">
                  status :
                </span>
                <span class="text-xs px-3 bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1 font-semibold">
                  Active
                </span>
              </div>
            )}

          {planDetails &&
            !planDetails?.subscription?.name.includes("Freemium") && (
              <div className="">
                <span class="block text-xs uppercase text-gray-500 dark:text-neutral-500">
                  Payment method:
                </span>
                <div class="flex items-center gap-x-2">
                  <svg
                    class="size-5"
                    width="400"
                    height="248"
                    viewBox="0 0 400 248"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path d="M254 220.8H146V26.4H254V220.8Z" fill="#FF5F00" />
                      <path
                        d="M152.8 123.6C152.8 84.2 171.2 49 200 26.4C178.2 9.2 151.4 0 123.6 0C55.4 0 0 55.4 0 123.6C0 191.8 55.4 247.2 123.6 247.2C151.4 247.2 178.2 238 200 220.8C171.2 198.2 152.8 163 152.8 123.6Z"
                        fill="#EB001B"
                      />
                      <path
                        d="M400 123.6C400 191.8 344.6 247.2 276.4 247.2C248.6 247.2 221.8 238 200 220.8C228.8 198.2 247.2 163 247.2 123.6C247.2 84.2 228.8 49 200 26.4C221.8 9.2 248.6 0 276.4 0C344.6 0 400 55.4 400 123.6Z"
                        fill="#F79E1B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="400" height="247.2" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                    •••• 4242
                  </span>
                </div>
              </div>
            )}
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Grid --> */}

        <div class="mt-5 sm:mt-10">
          <h4 class="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200">
            Summary
          </h4>

          {/* <!-- List Group --> */}
          <ul class="mt-3 flex flex-col">
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.price?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.price?.mascot +
                      planDetails?.subscription?.price?.amount +
                      " / " +
                      planDetails?.subscription?.price?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.link?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.link?.limit +
                      " / " +
                      planDetails?.subscription?.link?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.aiCredit?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.aiCredit?.limit +
                      " / " +
                      planDetails?.subscription?.aiCredit?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.analytics?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.analytics?.description}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.domain?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.domain?.limit +
                      " / " +
                      planDetails?.subscription?.domain?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.qrCodes?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.qrCodes?.limit +
                      " / " +
                      planDetails?.subscription?.qrCodes?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>
                  {planDetails && planDetails?.subscription?.gallery?.label}
                </span>
                <span>
                  {planDetails &&
                    planDetails?.subscription?.gallery?.limit +
                      " / " +
                      planDetails?.subscription?.gallery?.cycleType}
                </span>
              </div>
            </li>
            <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-200">
              <div class="flex items-center justify-between w-full">
                <span>Amount paid</span>
                <span>
                  {planDetails?.subscription?.price?.mascot +
                    planDetails?.subscription?.price?.amount}
                </span>
              </div>
            </li>
          </ul>
          {/* <!-- End List Group --> */}
        </div>
      </div>
    </>
  );
};

export default PlanDetailsCard;
