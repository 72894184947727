import React from "react";

const Loader = () => {
  return (
    <>
      {/* <div className="flex h-[500px] pt-72 justify-center items-center">
        <iframe src="tmp.html"></iframe>
      </div> */}
      <div className="flex justify-center items-center h-screen">
        <div className="block dark:hidden">
          <div className="relative inline-block">
            <img
              src={require("../../assets/images/logo/logo.png")}
              alt="logo"
              className="block w-14 h-6 animate-pulse"
            />
            <div className="absolute top-1/2 left-1/2 w-28 h-28 -mt-14 -ml-14 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
          </div>
        </div>
        <div className="hidden dark:block">
          <div className="relative inline-block">
            <img
              src={require("../../assets/images/logo/logo_white.png")}
              alt="logo"
              className="block w-14 h-6 animate-pulse"
            />
            <div className="absolute top-1/2 left-1/2 w-28 h-28 -mt-14 -ml-14 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
