import React, { useState, useEffect } from "react";
import axios from "axios";
import AiTabCards from "./AiTabCards";

const AiTab = ({ id, countChild }) => {
  const [notFound, setNotFound] = useState(false);
  const [lastColor, setLastColor] = useState(null);
  const [linkData, setLinkData] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const colors = [
    "#007BFF",
    "#FF5733",
    "#32CD32",
    "#FFD700",
    "#FF00FF",
    "#40E0D0",
    "#FF7F50",
    "#6A0DAD",
    "#FF69B4",
    "#6F00FF",
    "#DC143C",
    "#00BFFF",
  ];

  let prevColor = 0;

  const randomColor = () => {
    let random = Math.floor(Math.random() * 10) % 10;
    if (random !== prevColor) {
      prevColor = random;
      return random;
    } else {
      while (prevColor == random) {
        random = Math.floor(Math.random() * 10) % 10;
      }
      prevColor = random;
      return random;
    }
  };
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [window.location.pathname]);
  useEffect(() => {
    const fetchKpi = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/core/dashboard-kpi`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);
        if (response.status === 200) {
          setKpiData(response.data.data);
        }
      } catch (error) {
        console.log(error);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    fetchKpi();
  }, []);

  useEffect(() => {
    const getAiDetails = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          linkId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/link-contents`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;
        if (response.status === 200) {
          setNotFound(false);
          setLinkData(data);
        }
        console.log(linkData);
      } catch (error) {
        if (error.response.status === 404) {
          setNotFound(true);
        }
      }
    };
    getAiDetails();
  }, [countChild]);

  return (
    <>
      {!notFound && (
        <>
          <div className="flex flex-col">
            <div className="flex -mt-4 justify-between">
              <div className="inline-flex relative bg-white border  rounded-[2rem] p-2 text-neutral-500 dark:bg-neutral-800 border-[#019ca2] items-center gap-x-2 px-4 text-sm">
                <span className="hidden sm:block">Available AI credits :</span>
                <svg height="14" width="14" fill="#019ca2" viewBox="0 0 24 24">
                  <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>
                {kpiData &&
                  kpiData?.aiCredits?.totalAiCredits -
                    kpiData?.aiCredits?.utilizedAiCredits}
              </div>
              <button
                class="btn dark:bg-neutral-800"
                onClick={() => (window.location.href = `/gen-ai?id=${id}`)}
              >
                <svg
                  height="18"
                  width="18"
                  fill="#FFFFFF"
                  viewBox="0 0 24 24"
                  data-name="Layer 1"
                  id="Layer_1"
                  class="sparkle"
                >
                  <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>

                <span class="text">Generate with AI</span>
              </button>
            </div>

            {linkData &&
              linkData.map((linkData, index) => (
                <AiTabCards
                  key={linkData.id}
                  linkData={linkData}
                  color={prevColor == null ? colors[0] : colors[randomColor()]}
                />
              ))}
          </div>
        </>
      )}
      {notFound && (
        <>
          <div className="  flex flex-1 flex-col justify-center items-center text-center">
            <div className="pb-8">
              <img
                src={require("../../assets/images/logo/logo_ai.png")}
                alt="logo"
                className="h-6 w-14"
              />
            </div>
            <div class="max-w-sm mx-auto flex flex-col justify-center items-center">
              <p class="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                Enhance Your Marketing with Atz AI
              </p>
              <p class="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                Transform Your Marketing Strategy with Tailored SMS and Email
                Content from Atz AI
              </p>
              <div className="pt-5">
                <button
                  class="btn"
                  onClick={() => (window.location.href = `/gen-ai?id=${id}`)}
                >
                  <svg
                    height="18"
                    width="18"
                    fill="#FFFFFF"
                    viewBox="0 0 24 24"
                    data-name="Layer 1"
                    id="Layer_1"
                    class="sparkle"
                  >
                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                  </svg>

                  <span class="text">Generate with AI</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AiTab;
