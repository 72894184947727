import React, { useEffect, useState } from "react";
import Dropdown from "../subcomponents/Dropdown";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Header = ({ url }) => {
  const [dark, setDark] = useState(
    localStorage.getItem("theme") && localStorage.getItem("theme") === "dark"
      ? true
      : false
  );

  const [collapseVisible, setCollapseVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  useEffect(() => {
    const html = document.querySelector("html");
    // if (dark) html.classList.toggle("dark");
    if (dark) {
      html.classList.add("dark");
    } else {
      html.classList.remove("dark");
    }
    localStorage.setItem("theme", dark ? "dark" : "light");
  }, [dark]);

  const toggleCollapse = () => {
    setCollapseVisible(!collapseVisible);
  };

  const toggleDark = () => {
    setDark(!dark);
  };

  return (
    <div>
      <header className="flex flex-wrap lg:justify-start lg:flex-nowrap z-50 w-full py-1 backdrop-blur-md  dark:bg-neutral-950 fixed bg-neutral-100 ">
        <nav
          className="max-w-[105rem] flex flex-wrap basis-full items-center w-full mx-auto px-4 sm:px-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center sm:gap-x-2 lg:gap-x-0 xl:gap-x-3">
            <Link to="/" className="flex-none rounded-md">
              <img
                src={
                  dark
                    ? require("../../assets/images/logo/logo_white.png")
                    : require("../../assets/images/logo/logo.png")
                }
                className="w-20 h-auto p-2"
                alt="logo"
              />
            </Link>
          </div>

          <div className="flex items-center lg:gap-x-1 ms-auto lg:order-3">
            <div className="flex items-center relative z-10 before:hidden lg:before:block before:w-px before:h-4 before:bg-gray-300 dark:before:bg-neutral-700">
              {/* Dark mode */}
              <button
                type="button"
                onClick={toggleDark}
                className={`hs-dark-mode ${
                  dark ? "hidden" : "block"
                } rounded-full`}
              >
                <span className="group inline-flex flex-shrink-0 justify-center items-center size-9 font-medium rounded-full text-gray-800 hover:bg-gray-200 dark:text-neutral-200 dark:hover:bg-neutral-800">
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
                  </svg>
                </span>
              </button>
              <button
                type="button"
                onClick={toggleDark}
                className={`hs-dark-mode ${
                  dark ? "block" : "hidden"
                } rounded-full`}
              >
                <span className="group inline-flex flex-shrink-0 justify-center items-center size-9 font-medium rounded-full text-gray-800 hover:bg-gray-200 dark:text-neutral-200 dark:hover:bg-neutral-800">
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="4" />
                    <path d="M12 2v2" />
                    <path d="M12 20v2" />
                    <path d="m4.93 4.93 1.41 1.41" />
                    <path d="m17.66 17.66 1.41 1.41" />
                    <path d="M2 12h2" />
                    <path d="M20 12h2" />
                    <path d="m6.34 17.66-1.41 1.41" />
                    <path d="m19.07 4.93-1.41 1.41" />
                  </svg>
                </span>
              </button>
            </div>
            {!collapseVisible && (
              <div className="hidden sm:flex p-0.5 space-x-0.5 items-center border border-gray-200 rounded-full dark:border-white/20">
                <a
                  className="py-1 px-5 inline-flex items-center gap-x-3 text-gray-800 text-xs font-medium rounded-full hover:text-gray-600 dark:text-white dark:hover:text-neutral-200"
                  href={url}
                >
                  <span className="text-xs text-teal-500 relative -ms-1">
                    <span className="flex absolute top-[5px] size-1.5">
                      <span className="animate-ping absolute inline-flex size-full rounded-full bg-[#019ca2] opacity-75 dark:bg-[#019ca2]"></span>
                      <span className="relative inline-flex rounded-full size-1.5 bg-[#019ca2]"></span>
                    </span>
                    {/* {user ? (
                      <span className="ps-2.5 ">{user.name}</span>
                    ) : (
                      <span className="ps-2.5 ">Get Started</span>
                    )} */}
                    <span className="ps-2.5 ">Get Started</span>
                  </span>
                </a>
              </div>
            )}

            <div className="ms-1 lg:hidden">
              <button
                type="button"
                class="hs-collapse-toggle w-7 h-[30px] inline-flex rounded-full size-9 justify-center items-center gap-x-2 text-sm font-medium border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                id="hs-pro-dmh-collapse"
                aria-expanded="false"
                aria-controls="hs-pro-dmh"
                aria-label="Toggle navigation"
                data-hs-collapse="#hs-pro-dmh"
              >
                <svg
                  className={`hs-collapse-open block
                   flex-shrink-0 size-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
              </button>
              {/* <button
                type="button"
                className="hs-collapse-toggle size-9 inline-flex justify-center items-center gap-x-2 rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-white dark:hover:bg-white/10"
                data-hs-collapse="#docs-navbar"
                aria-controls="docs-navbar"
                aria-label="Toggle navigation"
              >
                <svg
                  className={`hs-collapse-open block
                   flex-shrink-0 size-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" x2="21" y1="6" y2="6" />
                  <line x1="3" x2="21" y1="12" y2="12" />
                  <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
                <svg
                  className={`hs-collapse-open ${
                    collapseVisible ? "block" : "hidden"
                  } flex-shrink-0 size-4`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button> */}
            </div>
          </div>

          <div class="ml-10 lg:order-1 basis-full grow lg:basis-auto lg:grow-0">
            {/* <!-- Collapse --> */}
            <div
              id="hs-pro-dmh"
              class="hs-collapse hidden overflow-hidden transition-all duration-300 lg:block"
              aria-labelledby="hs-pro-dmh-collapse"
            >
              <div class="overflow-hidden overflow-y-auto max-h-[75vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                <div class="lg:flex lg:items-center lg:gap-x-2 pt-2 lg:pt-0">
                  {/* <!-- Dropdown Link --> */}
                  <div class="hs-dropdown lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover] border-t border-dashed border-gray-200 first:border-t-0 py-1 first:pt-0 last:pb-0 lg:border-0 lg:py-0 dark:border-neutral-700">
                    {/* <!-- Link Button --> */}
                    <button
                      id="hs-pro-dnlfwd"
                      type="button"
                      class="hs-dropdown-toggle py-2 px-3 lg:px-2.5 lg:px-2 w-full lg:w-auto flex items-center text-sm text-start font-medium text-gray-800 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-200 dark:hover:bg-neutral-700/40 dark:focus:bg-neutral-700"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      aria-label="Dropdown"
                    >
                      Services
                      <svg
                        class="hs-dropdown-open:-rotate-180 lg:hs-dropdown-open:rotate-0 duration-300 ms-auto lg:ms-2 shrink-0 size-4 text-gray-400 lg:text-gray-600 dark:text-neutral-600 lg:dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>
                    {/* <!-- End Link Button --> */}

                    {/* <!-- Dropdown Menu --> */}
                    <div
                      class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full hidden z-10 top-full start-0 min-w-60 bg-white lg:rounded-lg lg:shadow-xl shadow-gray-200 before:absolute before:-top-4 before:start-0 before:w-full before:h-5 dark:bg-neutral-800 dark:shadow-neutral-900 "
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="hs-pro-dnlfwd"
                    >
                      <div class="max-w-[85rem] w-full mx-auto lg:px-8">
                        {/* <!-- Grid --> */}
                        <div class="py-1 lg:py-2 lg:-mx-2 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                          <div class="flex flex-col">
                            {/* <!-- Link --> */}
                            <Link
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              to="/services/links"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M14.6928 9.30707C14.9857 9.59997 14.9857 10.0748 14.6928 10.3677L10.3677 14.6928C10.0748 14.9857 9.59997 14.9857 9.30707 14.6928C9.01418 14.3999 9.01418 13.9251 9.30707 13.6322L13.6322 9.30707C13.9251 9.01418 14.3999 9.01418 14.6928 9.30707Z"
                                  fill="currentColor"
                                />
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  Links
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  Create, manage, and monitor shortened links
                                  for streamlined sharing and tracking.
                                </p>
                              </div>
                            </Link>
                            {/* <!-- End Link --> */}
                            {/*  */}
                            {/* <!-- Link --> */}
                            <Link
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              to="/services/branded-domains"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                width="24"
                                height="24"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="currentColor"
                                  d="M13.903 8c.06.629.097 1.292.097 2a1 1 0 01-2 0c0-.713-.039-1.378-.105-2H8.092c-.139 1.33-.14 2.67-.004 4H10a1 1 0 010 2H8.416c.469 2.08 1.202 3.41 1.585 4 0 0 .789.013.926.002a1.008 1.008 0 011.07.925 1 1 0 01-.924 1.07C10.979 20.004 10 20 10 20 4.486 20 0 15.514 0 10 0 4.485 4.486 0 10 0s10 4.485 10 10c0 .379-.036.749-.079 1.116a1.001 1.001 0 01-1.987-.233c.035-.291.066-.583.066-.883 0-.692-.098-1.36-.263-2h-3.834zM9.988 2.036c-.386.606-1.1 1.931-1.564 3.964h3.137c-.466-2.033-1.185-3.358-1.573-3.964zM2.263 12h3.819a21.604 21.604 0 01-.095-1.996c0-.67.033-1.338.096-2.004h-3.82A7.946 7.946 0 002 10a7.96 7.96 0 00.263 2zm.819 2a8.031 8.031 0 004.437 3.601A16.293 16.293 0 016.371 14H3.082zm3.291-8a16.235 16.235 0 011.15-3.603A8.03 8.03 0 003.082 6h3.291zm7.239 0h3.305a8.029 8.029 0 00-4.465-3.612A16.128 16.128 0 0113.612 6z"
                                />
                                <path
                                  fill="currentColor"
                                  d="M15.223 18.945l-2.55-5.61a.5.5 0 01.662-.662l5.61 2.55a.5.5 0 01.017.903l-1.742.87a.5.5 0 00-.223.224l-.871 1.742a.5.5 0 01-.903-.017z"
                                />
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  Branded Domains
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  Customize links with your brand’s URL for a
                                  seamless and professional online presence.
                                </p>
                              </div>
                            </Link>
                            {/* <!-- End Link --> */}

                            {/* <!-- Link --> */}
                            <Link
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              to="/services/atz-ai"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                height="24"
                                width="24"
                                version="1.1"
                                id="_x32_"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                fill="currentColor"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path
                                      class="st0"
                                      d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                                    ></path>{" "}
                                    <path
                                      class="st0"
                                      d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                                    ></path>{" "}
                                    <path
                                      class="st0"
                                      d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                                    ></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  Atz AI
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  Enhances every link with smart, AI-driven
                                  insights and contextual understanding
                                </p>
                              </div>
                            </Link>
                            {/* <!-- End Link --> */}
                          </div>
                          {/* <!-- End Col --> */}

                          <div class="flex flex-col">
                            {/* <!-- Link --> */}
                            <Link
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              to="/services/analytics"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                viewBox="-1 0 24 24"
                                height="24"
                                width="24"
                                id="meteor-icon-kit__regular-analytics"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3 15C2.44772 15 2 15.4477 2 16V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V16C4 15.4477 3.55228 15 3 15zM3 13C4.65685 13 6 14.3431 6 16V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V16C0 14.3431 1.34315 13 3 13zM11 0C12.6569 0 14 1.34315 14 3V21C14 22.6569 12.6569 24 11 24C9.3431 24 8 22.6569 8 21V3C8 1.34315 9.3431 0 11 0zM11 2C10.4477 2 10 2.44772 10 3V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V3C12 2.44772 11.5523 2 11 2zM19 7C20.6569 7 22 8.34315 22 10V21C22 22.6569 20.6569 24 19 24C17.3431 24 16 22.6569 16 21V10C16 8.34315 17.3431 7 19 7zM19 9C18.4477 9 18 9.44771 18 10V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V10C20 9.44771 19.5523 9 19 9z"
                                    fill="currentColor"
                                  ></path>
                                </g>
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  Analytics
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  Maximize power efficiency with Atz through
                                  cutting-edge real-time analytics.
                                </p>
                              </div>
                            </Link>
                            {/* <!-- End Link --> */}

                            {/* <!-- Link --> */}
                            <a
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              href="/services/qr-code"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                fill="currentColor"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M21,2H15a1,1,0,0,0-1,1V9a1,1,0,0,0,1,1h1v2h2V10h2v2h2V3A1,1,0,0,0,21,2ZM18,8H16V4h4V8ZM3,10H9a1,1,0,0,0,1-1V3A1,1,0,0,0,9,2H3A1,1,0,0,0,2,3V9A1,1,0,0,0,3,10ZM4,4H8V8H4ZM5,16v2H3V16ZM3,20H5v2H3Zm4-2v2H5V18Zm0-2H5V14H7V12H9v4ZM5,12v2H3V12Zm9,3v1H13V14H11v4h3v3a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V15a1,1,0,0,0-1-1H16V12H14Zm6,1v4H16V16ZM9,18h2v2h1v2H7V20H9ZM13,6H11V4h2ZM11,8h2v4H11ZM5,5H7V7H5ZM17,5h2V7H17Zm2,14H17V17h2Z" />
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  QR Codes
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  Develop interactive QR codes for Atz that
                                  instantly engage and direct users to desired
                                  content.
                                </p>
                              </div>
                            </a>
                            {/* <!-- End Link --> */}

                            {/* <!-- Link --> */}
                            <a
                              class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              href="/services/gallery"
                            >
                              <svg
                                class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="8"
                                  r="2"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                              <div class="grow">
                                <p class="font-medium text-sm text-gray-800 dark:text-neutral-200">
                                  Gallery
                                </p>
                                <p class="text-sm text-gray-500 dark:text-neutral-500">
                                  A link-in-bio that showcases multiple links on
                                  one page for easy redirection.
                                </p>
                              </div>
                            </a>
                            {/* <!-- End Link --> */}
                          </div>
                          {/* <!-- End Col --> */}

                          <div class="mt-2 md:mt-0 flex flex-col">
                            <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">
                              Customer stories
                            </span>

                            {/* <!-- Link --> */}
                            <a
                              class="p-3 flex gap-x-5 items-center rounded-xl hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                              href="#"
                            >
                              <img
                                class="size-32 rounded-lg"
                                src="https://images.unsplash.com/photo-1648737967328-690548aec14f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80"
                                alt="Avatar"
                              />
                              <div class="grow">
                                <p class="text-sm text-gray-800 dark:text-neutral-400">
                                  The real-time analytics are top-notch. I now
                                  have access to comprehensive data on link
                                  performance, which has been instrumental in
                                  optimizing my marketing strategies.
                                  Understanding click-through rates, geographic
                                  data, and user engagement has given me a
                                  significant edge.
                                </p>
                              </div>
                            </a>
                            {/* <!-- End Link --> */}
                          </div>
                          {/* <!-- End Col --> */}
                        </div>
                        {/* <!-- End Grid --> */}
                      </div>
                    </div>
                    {/* <!-- End Dropdown Menu --> */}
                  </div>
                  {/* <!-- End Dropdown Link --> */}
                  <div class="hs-dropdown lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover] border-t border-dashed border-gray-200 first:border-t-0 py-1 first:pt-0 last:pb-0 lg:border-0 lg:py-0 dark:border-neutral-700">
                    {/* <!-- Link Button --> */}
                    <button
                      id="hs-pro-dnldrd"
                      type="button"
                      class="hs-dropdown-toggle py-2 px-3 lg:px-2.5 lg:px-2 w-full lg:w-auto flex items-center text-sm text-start font-medium text-gray-800 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-200 dark:hover:bg-neutral-700/40 dark:focus:bg-neutral-700"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      aria-label="Dropdown"
                    >
                      Features
                      <svg
                        class="hs-dropdown-open:-rotate-180 lg:hs-dropdown-open:rotate-0 duration-300 ms-auto lg:ms-2 shrink-0 size-4 text-gray-400 lg:text-gray-600 dark:text-neutral-600 lg:dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>
                    {/* <!-- End Link Button --> */}

                    {/* <!-- Dropdown Menu --> */}
                    <div
                      class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full hidden z-10 top-full lg:w-80 bg-white lg:rounded-lg lg:shadow-xl shadow-gray-200 before:absolute before:-top-3 before:start-0 before:w-full before:h-5 dark:bg-neutral-800 dark:shadow-neutral-900"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="hs-pro-dnldrd"
                    >
                      <div class="py-1 lg:p-2 space-y-1">
                        {/* <!-- Link --> */}
                        <Link
                          class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                          to="/services/links"
                          onClick={() => {
                            const element =
                              document.getElementById("campaign-section");
                            element?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          <svg
                            class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                              fill="currentColor"
                            />
                            <path
                              d="M14.6928 9.30707C14.9857 9.59997 14.9857 10.0748 14.6928 10.3677L10.3677 14.6928C10.0748 14.9857 9.59997 14.9857 9.30707 14.6928C9.01418 14.3999 9.01418 13.9251 9.30707 13.6322L13.6322 9.30707C13.9251 9.01418 14.3999 9.01418 14.6928 9.30707Z"
                              fill="currentColor"
                            />
                          </svg>
                          <div class="grow">
                            <span class="block font-semibold text-sm text-gray-800 dark:text-neutral-200">
                              UTM Campaigns
                            </span>
                            <p class="text-sm text-gray-500 dark:text-neutral-500">
                              Track links with UTM parameters
                            </p>
                          </div>
                        </Link>
                        {/* <!-- End Link --> */}

                        <div class="my-2 border-t border-gray-100 dark:border-neutral-800"></div>

                        {/* <!-- Link --> */}
                        <Link
                          class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                          to="/services/links"
                          onClick={() => {
                            const element =
                              document.getElementById("utm-section");
                            element?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          <svg
                            fill="currentColor"
                            class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                            height="24"
                            width="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path d="M13,20H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v2a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,12.05,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h7a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM8,8a1,1,0,0,0,0,2H9A1,1,0,0,0,9,8Zm6,4H8a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm6.71,5.29-2-2a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-2,2a1,1,0,0,0,1.42,1.42l.29-.3V21a1,1,0,0,0,2,0V18.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,20.71,17.29ZM12,18a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Z"></path>
                            </g>
                          </svg>
                          <div class="grow">
                            <span class="block font-semibold text-sm text-gray-800 dark:text-neutral-200">
                              Bulk Shortening
                            </span>
                            <p class="text-sm text-gray-500 dark:text-neutral-500">
                              Mass URL shortening
                            </p>
                          </div>
                        </Link>
                        {/* <!-- End Link --> */}

                        <div class="my-2 border-t border-gray-100 dark:border-neutral-800"></div>

                        {/* <!-- Link --> */}
                        <Link
                          class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                          to="/services/links"
                          onClick={() => {
                            const element =
                              document.getElementById("analytics-section");
                            element?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          <svg
                            class="shrink-0 size-4 mt-1 text-gray-800 dark:text-neutral-200"
                            viewBox="-1 0 24 24"
                            height="24"
                            width="24"
                            id="meteor-icon-kit__regular-analytics"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3 15C2.44772 15 2 15.4477 2 16V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V16C4 15.4477 3.55228 15 3 15zM3 13C4.65685 13 6 14.3431 6 16V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V16C0 14.3431 1.34315 13 3 13zM11 0C12.6569 0 14 1.34315 14 3V21C14 22.6569 12.6569 24 11 24C9.3431 24 8 22.6569 8 21V3C8 1.34315 9.3431 0 11 0zM11 2C10.4477 2 10 2.44772 10 3V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V3C12 2.44772 11.5523 2 11 2zM19 7C20.6569 7 22 8.34315 22 10V21C22 22.6569 20.6569 24 19 24C17.3431 24 16 22.6569 16 21V10C16 8.34315 17.3431 7 19 7zM19 9C18.4477 9 18 9.44771 18 10V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V10C20 9.44771 19.5523 9 19 9z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                          <div class="grow">
                            <span class="block font-semibold text-sm text-gray-800 dark:text-neutral-200">
                              Analytics
                            </span>
                            <p class="text-sm text-gray-500 dark:text-neutral-500">
                              A centralised place to track & analyze performance
                            </p>
                          </div>
                        </Link>
                        {/* <!-- End Link --> */}
                      </div>
                    </div>
                    {/* <!-- End Dropdown Menu --> */}
                  </div>
                  {/* <!-- Dropdown Link --> */}
                  <div class="hs-dropdown lg:inline-block [--strategy:static] lg:[--strategy:absolute] [--adaptive:none] lg:[--trigger:hover] border-t border-dashed border-gray-200 first:border-t-0 py-1 first:pt-0 last:pb-0 lg:border-0 lg:py-0 dark:border-neutral-700 hidden">
                    {/* <!-- Link Button --> */}
                    <button
                      id="hs-pro-dnlcd"
                      type="button"
                      class="hs-dropdown-toggle py-2 px-3 lg:px-2.5 lg:px-2 w-full lg:w-auto flex items-center text-sm text-start font-medium text-gray-800 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-200 dark:hover:bg-neutral-700/40 dark:focus:bg-neutral-700 hidden"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      aria-label="Dropdown"
                    >
                      Resources
                      <svg
                        class="hs-dropdown-open:-rotate-180 lg:hs-dropdown-open:rotate-0 duration-300 ms-auto lg:ms-2 shrink-0 size-4 text-gray-400 lg:text-gray-600 dark:text-neutral-600 lg:dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>
                    {/* <!-- End Link Button --> */}
                    {/*  */}

                    {/* <!-- Dropdown Menu --> */}
                    <div
                      class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] lg:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full lg:w-[750px] hidden z-10 top-full start-0 lg:start-auto min-w-60 bg-white lg:rounded-lg lg:shadow-xl shadow-gray-200 before:absolute before:-top-3 before:start-0 before:w-full before:h-5 dark:bg-neutral-800 dark:shadow-neutral-900"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="hs-pro-dnlcd"
                    >
                      {/* <!-- Grid --> */}
                      <div class="grid grid-cols-1 lg:grid-cols-5 gap-3 lg:gap-0">
                        <div class="col-span-3 py-1 lg:p-2">
                          {/* <!-- Grid --> */}
                          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-1">
                            <div>
                              <div class="flex flex-col">
                                <div class="space-y-0.5">
                                  <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">
                                    About us
                                  </span>

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                                      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Support Docs
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <rect
                                        width="7"
                                        height="7"
                                        x="14"
                                        y="3"
                                        rx="1"
                                      />
                                      <path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Integrations
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                                      <polyline points="14 2 14 8 20 8" />
                                      <line x1="16" x2="8" y1="13" y2="13" />
                                      <line x1="16" x2="8" y1="17" y2="17" />
                                      <line x1="10" x2="8" y1="9" y2="9" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Guides
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="m7 11 2-2-2-2" />
                                      <path d="M11 13h4" />
                                      <rect
                                        width="18"
                                        height="18"
                                        x="3"
                                        y="3"
                                        rx="2"
                                        ry="2"
                                      />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        API Reference
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        API Status
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}
                                </div>
                              </div>
                            </div>
                            {/* <!-- End Col --> */}

                            <div>
                              <div class="flex flex-col">
                                <div class="space-y-0.5">
                                  <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">
                                    Support
                                  </span>

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <circle cx="12" cy="12" r="10" />
                                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                      <path d="M12 17h.01" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Help Center
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <circle cx="12" cy="12" r="4" />
                                      <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Developer Hub
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                      <circle cx="9" cy="7" r="4" />
                                      <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Community Forum
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}
                                </div>

                                <div class="mt-3 lg:mt-7 space-y-0.5">
                                  <span class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">
                                    Partners
                                  </span>

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="M4 7V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-6" />
                                      <polyline points="14 2 14 8 20 8" />
                                      <path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                      <path d="M7 16.5 8 22l-3-1-3 1 1-5.5" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Become a Partner
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}

                                  {/* <!-- Link --> */}
                                  <a
                                    class="p-2 flex gap-x-3 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-neutral-200 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    href="#"
                                  >
                                    <svg
                                      class="shrink-0 size-4 mt-0.5 text-gray-800 dark:text-neutral-200"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path d="m15 5 4 4" />
                                      <path d="M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13" />
                                      <path d="m8 6 2-2" />
                                      <path d="m2 22 5.5-1.5L21.17 6.83a2.82 2.82 0 0 0-4-4L3.5 16.5Z" />
                                      <path d="m18 16 2-2" />
                                      <path d="m17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17" />
                                    </svg>
                                    <div class="grow">
                                      <p class="text-sm text-gray-800 dark:text-neutral-200">
                                        Build on Preline
                                      </p>
                                    </div>
                                  </a>
                                  {/* <!-- End Link --> */}
                                </div>
                              </div>
                            </div>
                            {/* <!-- End Col --> */}
                          </div>
                          {/* <!-- End Grid --> */}
                        </div>
                        {/* <!-- End Col --> */}

                        <div class="lg:col-span-2">
                          <div class="p-2 lg:p-4 lg:h-full lg:flex-1 flex flex-col lg:bg-gray-50 lg:rounded-br-lg dark:lg:bg-neutral-700">
                            <span class="mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-neutral-200">
                              Customer stories
                            </span>

                            {/* <!-- Link --> */}
                            <a
                              class="group flex flex-row items-center lg:items-start lg:flex-col gap-4 lg:gap-2 focus:outline-none"
                              href="#"
                            >
                              <img
                                class="shrink-0 lg:w-full size-28 object-cover rounded-lg"
                                src="https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80"
                                alt="Customer Story Image"
                              />
                              <div class="grow">
                                <p class="text-sm text-gray-800 dark:text-neutral-200">
                                  Preline Projects has proved to be most
                                  efficient cloud based project tracking and bug
                                  tracking tool.
                                </p>
                                <p class="mt-3 inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium dark:text-blue-400 dark:hover:text-blue-500">
                                  Learn more
                                  <svg
                                    class="shrink-0 size-4 transition ease-in-out group-hover:translate-x-1 group-focus:translate-x-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path d="m9 18 6-6-6-6" />
                                  </svg>
                                </p>
                              </div>
                            </a>
                            {/* <!-- End Link --> */}
                          </div>
                        </div>
                        {/* <!-- End Col --> */}
                      </div>
                      {/* <!-- End Grid --> */}
                    </div>
                    {/* <!-- End Dropdown Menu --> */}
                  </div>
                  {/* <!-- End Dropdown Link --> */}

                  {/* <!-- Dropdown Link --> */}

                  {/* <!-- End Dropdown Link --> */}
                </div>
              </div>
            </div>
            {/* <!-- End Collapse --> */}
          </div>

          {/* <div
            id="docs-navbar"
            className={`hs-collapse ${
              collapseVisible ? "block" : "hidden"
            } overflow-hidden transition-all duration-300 basis-full grow ms-auto lg:block lg:w-auto lg:basis-auto lg:order-2`}
          >
            <div className="flex flex-col mt-5 lg:flex-row lg:items-center lg:justify-center lg:mt-0">
              <a
                className="inline-flex items-center text-gray-800 p-2 text-sm font-medium rounded-lg hover:text-teal-600 focus:outline-none focus:text-teal-600 dark:text-neutral-200 dark:hover:text-teal-400 dark:focus:text-neutral-400"
                href="./index.html"
              >
                <svg
                  className="flex-shrink-0 size-4 me-3 block lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                  <polyline points="9 22 9 12 15 12 15 22" />
                </svg>
                Home
              </a>
              <span
                className="inline-flex items-center text-gray-800 p-2 text-sm font-medium rounded-lg hover:text-teal-600 focus:outline-none focus:text-teal-600 dark:text-neutral-200 dark:hover:text-teal-400 dark:focus:text-neutral-400"
                href="#"
              >
                <svg
                  className="flex-shrink-0 size-4 me-3 block lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                  <path d="M16 2v4M8 2v4m-5 4h18" />
                </svg>
                <Dropdown />
              </span>
              <a
                className="inline-flex items-center text-gray-800 p-2 text-sm font-medium rounded-lg hover:text-teal-600 focus:outline-none focus:text-teal-600 dark:text-neutral-200 dark:hover:text-teal-400 dark:focus:text-neutral-400"
                href="./docs/index.html"
              >
                <svg
                  className="flex-shrink-0 size-4 me-3 block lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                  <path d="M16 2v4M8 2v4m-5 4h18" />
                </svg>
                Features
              </a>
              <a
                className="inline-flex items-center text-gray-800 p-2 text-sm font-medium rounded-lg hover:text-teal-600 focus:outline-none focus:text-teal-600 dark:text-neutral-200 dark:hover:text-teal-400 dark:focus:text-neutral-400"
                href="./docs/index.html"
              >
                <svg
                  className="flex-shrink-0 size-4 me-3 block lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                  <path d="M16 2v4M8 2v4m-5 4h18" />
                </svg>
                Blog
              </a>
              <a
                className="inline-flex items-center text-gray-800 p-2 text-sm font-medium rounded-lg hover:text-teal-600 focus:outline-none focus:text-teal-600 dark:text-neutral-200 dark:hover:text-teal-400 dark:focus:text-neutral-400"
                href="./about.html"
              >
                <svg
                  className="flex-shrink-0 size-4 me-3 block lg:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20.42 4.58a2 2 0 0 1 0 2.83L11.83 16l-4.24.71.71-4.24 8.59-8.59a2 2 0 0 1 2.83 0z" />
                  <path d="m15 7 3 3" />
                </svg>
                About Us
              </a>
            </div>
          </div> */}
        </nav>
      </header>
    </div>
  );
};

export default Header;
