import React, { useState, useEffect } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import { useLocation } from "react-router-dom";
import EmailValidationForm from "../EmailValidation";
import UrlValidate from "../UrlValidate";
import "preline/preline";
import CreateLinkBody from "./CreateLinkBody";
import DashboardFooter from "../Dashboard/DashboardFooter";
const CreateLink = () => {
  localStorage.setItem("activeLink", "/links");
  const location = useLocation();

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <div className="flex flex-col h-screen bg-neutral-100 dark:bg-neutral-950">
        <DashboardHeader />
        <DashboardSidebar />
        <DashboardFooter />
        <main id="content" class="lg:ps-[260px] pt-[59px] pr-3">
          <ol class="md:hidden py-3 px-2 sm:px-5 flex items-center whitespace-nowrap">
            <li class="flex items-center text-sm text-gray-600 dark:text-neutral-500">
              Links
              <svg
                class="flex-shrink-0 mx-1 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </li>

            <li
              class="text-sm font-semibold text-gray-800 truncate dark:text-neutral-200"
              aria-current="page"
            >
              Create Link
            </li>
          </ol>
          <CreateLinkBody />
        </main>
      </div>
    </>
  );
};

export default CreateLink;
