import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axiosInstance from "../src/instances/axiosInstance";
import Loader from "./components/subcomponents/Loader";
import axios from "axios";

const PrivateRoute = ({ element: Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null to indicate loading state
  if (!localStorage.getItem("token")) {
    window.location.href = "/";
  }

  const introspectAPI = async () => {
    const data = JSON.stringify({
      data: {
        clientName: "atz",
      },
    });
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    const dataRefresh = JSON.stringify({
      data: {
        clientName: "atz",
        refreshToken: `${token.refresh_token}`,
      },
    });
    const { access_token: accessToken } = token;
    const config = {
      method: "post",
      url: "/secured/introspect",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };
    const configRefresh = {
      method: "post",
      url: "/core/refresh-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataRefresh,
    };

    try {
      const introspectResponse = await axiosInstance(config);
      if (introspectResponse.data.data.active) {
        localStorage.setItem("auth", true);
        setIsAuthenticated(true);
      } else {
        try {
          const responseRefresh = await axiosInstance(configRefresh);
          const newToken = responseRefresh.data.data;
          localStorage.setItem("token", JSON.stringify(newToken));
          localStorage.setItem("auth", true);
          setIsAuthenticated(true);
        } catch (e) {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = "/session-expired";
        }
      }
    } catch (error) {
      //handling 401 for introspect API
      if (error.response.status === 401) {
        //get the refresh
        try {
          const refreshResponse = await axiosInstance(configRefresh);
          const newToken = refreshResponse.data.data;
          localStorage.setItem("token", JSON.stringify(newToken));
          localStorage.setItem("auth", true);
          setIsAuthenticated(true);
        } catch (e) {
          localStorage.clear();
          setIsAuthenticated(false);
          window.location.href = "/session-expired";
        }
      } else {
        localStorage.clear();
        setIsAuthenticated(false);
        window.location.href = "/session-expired";
      }
    }
  };

  axios.interceptors.request.use(
    async (req) => {
      try {
        let token = await introspectAPI();
        if (isAuthenticated) {
          token = JSON.parse(localStorage.getItem("token"));
          console.log(token);
          req.headers.Authorization = `Bearer ${token.access_token}`;
        }
        return req;
      } catch (error) {
        // Handle the error or redirect to login
        window.location.href = "/session-expired";
        // return Promise.reject(error);
      }
    },
    (error) => {
      window.location.href = "/session-expired";
      // return Promise.reject(error);
    }
  );
  axios.interceptors.response.use((res) => {
    if (res.status === 200) {
      setIsAuthenticated(true);
    }
    return res;
  });
  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     introspectAPI();
  //   } else {
  //     setIsAuthenticated(false);
  //   }
  // }, []);

  // return isAuthenticated ? <Component /> : <Navigate to="/" />;
  return (
    <>
      <div className={!isAuthenticated ? "hidden" : ""}>
        <Component />
      </div>
      <div className={isAuthenticated ? "hidden" : ""}>
        <Loader />
      </div>
    </>
  );
};

export default PrivateRoute;
