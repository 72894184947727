import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import axios from "axios";
import TnxDetailsCard from "../AiCredits/TnxDetailsCard";
import SmallLoader from "../subcomponents/SmallLoader";
import DashboardFooter from "../Dashboard/DashboardFooter";

const Transactions = () => {
  const location = useLocation();
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchInput1, setSearchInput1] = useState("");
  const [loadingTxn, setLoadingTxn] = useState(false);
  const [loadingTxn1, setLoadingTxn1] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [noDataFound1, setNoDataFound1] = useState(false);
  const [txnData, setTxnData] = useState([]);
  const [txnData1, setTxnData1] = useState([]);
  const [totalTxn, setTotalTxn] = useState(0);
  const [totalTxn1, setTotalTxn1] = useState(0);
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSearchInput1 = (e) => {
    setSearchInput1(e.target.value);
  };
  useEffect(() => {
    localStorage.setItem("activeLink", "/transactions");
  });
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!localStorage.getItem("activeTab")) {
      localStorage.setItem("activeTab", "one");
    }
  });
  const handleTab = (tab) => {
    if (tab == "one") {
      localStorage.setItem("activeTab", "one");
      setTab1(true);
      setTab2(false);
    } else if (tab == "two") {
      localStorage.setItem("activeTab", "two");
      setTab1(false);
      setTab2(true);
    }
  };
  useEffect(() => {
    const fetchTransactions = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: index, // MANDATORY
          itemsPerIndex: 5, // MANDATORY

          search: searchInput1, // OPTIONAL search works for only paymentId received from razorpay
          fromDate: startDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy
          toDate: endDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy},
        },
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/ai-credits-transactions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingTxn(true);
        const response = await axios(config);
        console.log(response.data.data);
        setTxnData(response.data.data);
        setTotalTxn(response.data.data.total);
        setNoDataFound(false);
        if (response.status === 200) {
          setLoadingTxn(false);
        }
      } catch (e) {
        setLoadingTxn(false);
        setNoDataFound(true);
        console.log(e);
      }
    };
    fetchTransactions();
  }, [startDate, endDate, index, searchInput1]);
  useEffect(() => {
    const fetchTransactionsSub = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: index1, // MANDATORY
          itemsPerIndex: 5, // MANDATORY

          search: searchInput, // OPTIONAL search works for only paymentId received from razorpay
          fromDate: startDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy
          toDate: endDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy},
        },
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-transactions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingTxn1(true);
        const response = await axios(config);
        console.log(response.data.data);
        setTxnData1(response.data.data);
        setTotalTxn1(response.data.data.total);
        setNoDataFound1(false);
        if (response.status === 200) {
          setLoadingTxn1(false);
        }
      } catch (e) {
        setLoadingTxn1(false);
        setNoDataFound1(true);
        console.log(e);
      }
    };
    fetchTransactionsSub();
  }, [startDate, endDate, index1, searchInput]);
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div class="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            <div class="border-b-[1.5px] border-gray-200 dark:border-neutral-700">
              <nav class="-mb-0.5 flex gap-x-2 flex-wrap">
                {
                  <button
                    class={`${
                      localStorage.getItem("activeTab") == "one"
                        ? `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-[#000] text-sm font-medium whitespace-nowrap  text-gray-600 hover:text-gray-800 focus:outline-none  dark:text-neutral-200 dark:border-neutral-400    `
                        : `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-transparent text-sm whitespace-nowrap text-gray-500 
                       focus:outline-none  dark:text-neutral-500`
                    }`}
                    onClick={() => handleTab("one")}
                  >
                    <div
                      className={`${
                        localStorage.getItem("activeTab") == "one"
                          ? `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200 bg-neutral-100 dark:bg-neutral-700`
                          : `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200`
                      }`}
                    >
                      Subscriptions
                    </div>
                  </button>
                }
                {
                  <button
                    class={`${
                      localStorage.getItem("activeTab") == "two"
                        ? `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-[#000] text-sm font-medium whitespace-nowrap  text-gray-600 hover:text-gray-800 focus:outline-none  dark:text-neutral-200 dark:border-neutral-400  `
                        : `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-transparent text-sm whitespace-nowrap text-gray-500 
                       focus:outline-none  dark:text-neutral-500`
                    }`}
                    onClick={() => handleTab("two")}
                  >
                    <div
                      className={`${
                        localStorage.getItem("activeTab") == "two"
                          ? `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200 bg-neutral-100 dark:bg-neutral-700`
                          : `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200`
                      }`}
                    >
                      Ai Credits
                    </div>
                  </button>
                }
              </nav>
            </div>

            {localStorage.getItem("activeTab") === "one" && (
              <div className="">
                <div className="flex justify-between items-center">
                  <div class="flex flex-1 relative">
                    <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={searchInput}
                      onChange={handleSearchInput}
                      class="input-style py-[9px] px-3 ps-10 block w-full border-gray-200 focus:border-gray-200 focus:ring-0 dark:focus:border-neutral-700 rounded-lg dark:border-neutral-700 bg-inherit text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-inherit  dark:text-neutral-400 dark:placeholder:text-neutral-400 "
                      placeholder="Search transactions using Payment ID"
                    />
                  </div>
                </div>
                {loadingTxn1 && (
                  <div className="min-h-72 flex flex-1 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {noDataFound1 && !loadingTxn1 && (
                  <div className="text-sm sm:p-5 p-2">
                    <div class="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                      <svg
                        class="w-48 mx-auto mb-4"
                        width="178"
                        height="90"
                        viewBox="0 0 178 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-50 dark:stroke-neutral-700/10"
                        />
                        <rect
                          x="34.5"
                          y="58"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="61"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="73"
                          width="77"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-100 dark:stroke-neutral-700/30"
                        />
                        <rect
                          x="27"
                          y="36"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="39"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="51"
                          width="92"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <g filter="url(#filter19)">
                          <rect
                            x="12"
                            y="6"
                            width="154"
                            height="40"
                            rx="8"
                            fill="currentColor"
                            class="fill-white dark:fill-neutral-800"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="12.5"
                            y="6.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            stroke="currentColor"
                            class="stroke-gray-100 dark:stroke-neutral-700/60"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="20"
                            y="14"
                            width="24"
                            height="24"
                            rx="4"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700 "
                          />
                          <rect
                            x="52"
                            y="17"
                            width="60"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                          <rect
                            x="52"
                            y="29"
                            width="106"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter19"
                            x="0"
                            y="0"
                            width="178"
                            height="64"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1187_14810"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1187_14810"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>

                      <div class="max-w-sm mx-auto">
                        <p class="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                          Uh oh!
                        </p>
                        <p class="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                          It looks like you don't have any transactions yet.
                          Make a transaction to view it here.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="overflow-auto">
                  {!noDataFound1 && !loadingTxn1 && (
                    <>
                      <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700 ">
                        <thead>
                          <tr>
                            <th scope="col" class="w-1/6 ">
                              <div class="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                ID
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Payment ID
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class="py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Amount
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Status
                              </div>
                            </th>
                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Created on
                              </div>
                            </th>
                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                          {txnData1 &&
                            txnData1?.transactions?.map((data) => (
                              <TnxDetailsCard data={data} />
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>

                {!noDataFound1 && (
                  <nav
                    class="flex justify-end items-center gap-x-1 p-2"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      onClick={() => setIndex1(index1 - 1)}
                      disabled={index1 === 0}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {index1 + 1}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {Math.ceil(totalTxn1 / 5)}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setIndex1(index1 + 1)}
                      disabled={index1 + 1 === Math.ceil(totalTxn1 / 5)}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                )}
              </div>
            )}
            {localStorage.getItem("activeTab") === "two" && (
              <div>
                <div className="flex justify-between items-center">
                  <div class="flex flex-1 relative">
                    <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={searchInput1}
                      onChange={handleSearchInput1}
                      class="input-style py-[9px] px-3 ps-10 block w-full border-gray-200 border-gray-200 focus:border-gray-200 focus:ring-0 dark:focus:border-neutral-700  rounded-lg dark:border-neutral-700 bg-inherit text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-inherit  dark:text-neutral-400 dark:placeholder:text-neutral-400 "
                      placeholder="Search transactions using Payment ID"
                    />
                  </div>
                </div>
                {loadingTxn && (
                  <div className="min-h-72 flex flex-1 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {noDataFound && !loadingTxn && (
                  <div className="text-sm sm:p-5 p-2">
                    <div class="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                      <svg
                        class="w-48 mx-auto mb-4"
                        width="178"
                        height="90"
                        viewBox="0 0 178 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-50 dark:stroke-neutral-700/10"
                        />
                        <rect
                          x="34.5"
                          y="58"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="61"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="73"
                          width="77"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-100 dark:stroke-neutral-700/30"
                        />
                        <rect
                          x="27"
                          y="36"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="39"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="51"
                          width="92"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <g filter="url(#filter19)">
                          <rect
                            x="12"
                            y="6"
                            width="154"
                            height="40"
                            rx="8"
                            fill="currentColor"
                            class="fill-white dark:fill-neutral-800"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="12.5"
                            y="6.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            stroke="currentColor"
                            class="stroke-gray-100 dark:stroke-neutral-700/60"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="20"
                            y="14"
                            width="24"
                            height="24"
                            rx="4"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700 "
                          />
                          <rect
                            x="52"
                            y="17"
                            width="60"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                          <rect
                            x="52"
                            y="29"
                            width="106"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter19"
                            x="0"
                            y="0"
                            width="178"
                            height="64"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1187_14810"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1187_14810"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>

                      <div class="max-w-sm mx-auto">
                        <p class="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                          Uh oh!
                        </p>
                        <p class="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                          It looks like you don't have any transactions yet.
                          Make a transaction to view it here.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="overflow-auto">
                  {!noDataFound && !loadingTxn && (
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead>
                        <tr>
                          <th scope="col" class="w-1/6 ">
                            <div class="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Payment ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class="py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Amount
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Status
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Created on
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                        {txnData &&
                          txnData?.transactions?.map((data) => (
                            <TnxDetailsCard data={data} />
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
                {!noDataFound && (
                  <nav
                    class="flex justify-end items-center gap-x-1 p-2"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      onClick={() => setIndex(index - 1)}
                      disabled={index === 0}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {index + 1}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {Math.ceil(totalTxn / 5)}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setIndex(index + 1)}
                      disabled={index + 1 === Math.ceil(totalTxn / 5)}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Transactions;
