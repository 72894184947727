import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import { useLocation } from "react-router-dom";
const AtzAi = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const location = useLocation();

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <main id="content" className="pt-24 sm:pt-0">
        <div class="relative overflow-hidden before:absolute before:top-1/2 before:start-1/2  before:bg-no-repeat before:bg-center before:size-full before:-z-[1] before:transform before:-translate-y-1/2 before:-translate-x-1/2 ">
          {/* <!-- Hero --> */}
          <div class="flex justify-center items-center max-w-[85rem] lg:pt-24 px-4 sm:px-6 lg:px-8 mx-auto">
            {/* <!-- Grid --> */}
            <div class="grid lg:grid-cols-1 gap-8">
              <div class="flex flex-col justify-center items-center">
                <div class="mb-8">
                  <h1 class="text-3xl font-bold md:text-4xl lg:text-5xl lg:leading-tight dark:text-white text-center">
                    <span class="text-[#019ca2] dark:text-[#019ca2]">
                      Atz AI
                    </span>
                    <br />
                    <span class="text-3xl">
                      unlock superior marketing with AI-Driven content &
                      precision timing
                    </span>
                  </h1>

                  <div class="sm:flex auto-cols-max items-center gap-3 md:gap-6 mt-6 justify-center">
                    <span class="block text-xs font-semibold uppercase mb-3 sm:mb-0 dark:text-white">
                      Features :
                    </span>

                    {/* <!-- Frameworks --> */}
                    <dl class="flex auto-cols-max items-center gap-3 md:gap-6">
                      <dt class="sr-only">Compatible with:</dt>

                      <dd class="flex items-center gap-x-2 text-xs font-semibold uppercase dark:text-white">
                        <svg
                          class="w-6 h-auto"
                          width="130"
                          height="146"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                            fill="currentColor"
                          />
                          <path
                            d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.6928 9.30707C14.9857 9.59997 14.9857 10.0748 14.6928 10.3677L10.3677 14.6928C10.0748 14.9857 9.59997 14.9857 9.30707 14.6928C9.01418 14.3999 9.01418 13.9251 9.30707 13.6322L13.6322 9.30707C13.9251 9.01418 14.3999 9.01418 14.6928 9.30707Z"
                            fill="currentColor"
                          />
                        </svg>
                        UTM Campaigns
                      </dd>
                      <dd class="flex items-center gap-x-2 text-xs font-semibold uppercase dark:text-white">
                        <svg
                          class="w-6 h-auto"
                          width="160"
                          height="143"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M13,20H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v2a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,12.05,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h7a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM8,8a1,1,0,0,0,0,2H9A1,1,0,0,0,9,8Zm6,4H8a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm6.71,5.29-2-2a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-2,2a1,1,0,0,0,1.42,1.42l.29-.3V21a1,1,0,0,0,2,0V18.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,20.71,17.29ZM12,18a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Z"></path>
                          </g>
                        </svg>
                        Bulk Shortening
                      </dd>
                      <dd class="flex items-center gap-x-2 text-xs font-semibold uppercase dark:text-white">
                        <svg
                          class="w-6 h-auto"
                          width="164"
                          height="143"
                          viewBox="-1 0 24 24"
                          id="meteor-icon-kit__regular-analytics"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3 15C2.44772 15 2 15.4477 2 16V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V16C4 15.4477 3.55228 15 3 15zM3 13C4.65685 13 6 14.3431 6 16V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V16C0 14.3431 1.34315 13 3 13zM11 0C12.6569 0 14 1.34315 14 3V21C14 22.6569 12.6569 24 11 24C9.3431 24 8 22.6569 8 21V3C8 1.34315 9.3431 0 11 0zM11 2C10.4477 2 10 2.44772 10 3V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V3C12 2.44772 11.5523 2 11 2zM19 7C20.6569 7 22 8.34315 22 10V21C22 22.6569 20.6569 24 19 24C17.3431 24 16 22.6569 16 21V10C16 8.34315 17.3431 7 19 7zM19 9C18.4477 9 18 9.44771 18 10V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V10C20 9.44771 19.5523 9 19 9z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                        Analytics
                      </dd>
                    </dl>
                    {/* <!-- End Frameworks --> */}
                  </div>

                  <p class="mt-6 md:text-lg text-gray-600 dark:text-neutral-400 text-center">
                    Transform your marketing with Atz AI. Generate smart,
                    AI-driven content for SMS and email that’s perfectly timed
                    for each target country.
                    <br /> Enhance your click-through rates, improve engagement,
                    and streamline your workflow with our cutting-edge
                    solutions.
                    <br /> Start optimizing your content strategy today!
                  </p>
                </div>

                {/* <!-- Buttons --> */}
                <div class="grid gap-3 w-full sm:inline-flex place-content-center">
                  <a
                    class="py-2 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#1e8488] focus:bg-[#147f83] disabled:opacity-50 disabled:pointer-events-none"
                    href={url}
                  >
                    Get started
                    <svg
                      className="p-0.5"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.7242 8.45192L7.28876 7.19383C6.55281 8.18469 5.92055 9.24884 5.40213 10.3696L3.7242 8.45192ZM1.24742 8.6585L4.54987 12.4327L4.07152 13.6286C3.92296 14 4.01003 14.4242 4.29289 14.7071L4.60651 15.0207C2.90342 16.9411 1.9627 18.9496 1.05132 21.6838C0.931539 22.0431 1.02506 22.4393 1.29289 22.7071C1.56073 22.9749 1.95689 23.0685 2.31623 22.9487C5.05037 22.0373 7.05889 21.0966 8.97928 19.3935L9.29289 19.7071C9.57576 19.99 9.99997 20.077 10.3714 19.9285L11.5673 19.4501L15.3415 22.7526C15.5911 22.971 15.9327 23.0514 16.2535 22.9673C16.5744 22.8832 16.8326 22.6456 16.943 22.3328L19.9291 13.8722C21.8977 11.5428 23 8.57479 23 5.48078V2C23 1.44772 22.5523 1 22 1H18.5192C15.4252 1 12.4572 2.10225 10.1278 4.0709L1.66718 7.05701C1.35444 7.16739 1.11676 7.42565 1.03268 7.74646C0.948589 8.06728 1.02903 8.40891 1.24742 8.6585ZM3.68527 20.3147C4.31277 18.7992 5.017 17.5929 6.02356 16.4378L7.56223 17.9764C6.40713 18.983 5.20083 19.6872 3.68527 20.3147ZM10.2408 17.8266L9.70711 17.2929L6.70711 14.2929L6.17337 13.7592L6.88327 11.9844C7.53465 10.356 8.44936 8.84567 9.59079 7.51401L10.1674 6.84129C12.2572 4.40319 15.308 3 18.5192 3H21V5.48078C21 8.69196 19.5968 11.7428 17.1587 13.8326L16.486 14.4092C15.1543 15.5506 13.644 16.4653 12.0156 17.1167L10.2408 17.8266ZM15.5481 20.2758L13.6304 18.5979C14.7512 18.0795 15.8153 17.4472 16.8062 16.7112L15.5481 20.2758ZM15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8ZM16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </a>
                </div>
                {/* <!-- End Buttons --> */}
              </div>
              {/* <!-- End Col --> */}

              {/* <div
                class="flex justify-center items-center w-full h-[20rem] sm:h-[30rem] lg:h-[35rem] overflow-hidden"
                style={{ perspective: " 700px" }}
              >
                <img
                  src={require("../../assets/images/links_1.png")}
                  alt="links"
                  className=""
                />
              </div> */}
              {/* <!-- End Col --> */}
            </div>
            {/* <!-- End Grid --> */}
          </div>
          {/* <!-- End Hero --> */}
        </div>

        {/* template */}
        <div class="px-4 sm:px-6 lg:px-8 pt-12 mb-20 md:mb-10 lg:pt-16">
          {/* <!-- Title --> */}
          <div class="max-w-2xl mx-auto text-center mb-10">
            <h2 class="text-2xl font-bold lg:text-3xl lg:leading-tight dark:text-white">
              Why Choose Atz AI?
            </h2>
          </div>
          {/* <!-- End Title --> */}

          <div class="max-w-[85rem] mx-auto">
            <div class="text-center space-y-10 xl:space-y-16">
              {/* <!-- Card --> */}
              {/* <!-- Card --> */}
              <div id="agency" class="scroll-mt-12">
                <div class="relative p-0.5 bg-gradient-to-tl from-gray-100 via-transparent to-gray-100 rounded-xl overflow-hidden dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                  <div class="p-5 lg:p-8 bg-gradient-to-bl from-gray-200 via-white to-gray-50 rounded-xl dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                    {/* <!-- Grid --> */}
                    <div class="grid lg:grid-cols-2 lg:grid-cols-3 items-center gap-5">
                      <div class="lg:order-2 lg:col-span-1 lg:col-span-2">
                        <div class="grid grid-cols-2 sm:grid-cols-3 items-center gap-x-2 sm:gap-x-5">
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai4.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai5.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai6.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Col --> */}

                      <div class="lg:order-1 lg:col-span-1 lg:col-span-1 text-center lg:text-start">
                        <h3 class="text-xl lg:text-2xl font-semibold text-gray-800 dark:text-white">
                          AI-Powered Content Creation
                        </h3>
                        <p class="mt-1  md:text-base text-gray-600 dark:text-neutral-400">
                          <ul className="space-y-2 text-sm">
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Effortlessly produce compelling and personalized
                                messages for SMS and email.
                              </span>
                            </li>
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Our advanced AI ensures your content resonates
                                with your audience, driving higher engagement
                                and conversion rates.
                              </span>
                            </li>
                          </ul>
                        </p>
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>

                  <a
                    class="before:absolute before:inset-0 before:z-[9]"
                    href="#"
                  ></a>
                </div>
              </div>
              <div id="agency" class="scroll-mt-12">
                <div class="relative p-0.5 bg-gradient-to-tl from-gray-100 via-transparent to-gray-100 rounded-xl overflow-hidden dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                  <div class="p-5 lg:p-8 bg-gradient-to-bl from-gray-200 via-white to-gray-50 rounded-xl dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                    {/* <!-- Grid --> */}
                    <div class="grid lg:grid-cols-2 lg:grid-cols-3 items-center gap-5">
                      <div class="lg:order-1 lg:col-span-1 lg:col-span-2">
                        <div class="grid grid-cols-2 sm:grid-cols-3 items-center gap-x-2 sm:gap-x-5">
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai3.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai2.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai1.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Col --> */}

                      <div class="lg:order-2 lg:col-span-1 lg:col-span-1 text-center lg:text-start">
                        <h3 class="text-xl lg:text-2xl font-semibold text-gray-800 dark:text-white">
                          Optimal Timing for Maximum Impact
                        </h3>
                        <p class="mt-1 text-sm md:text-base text-gray-600 dark:text-neutral-400">
                          <ul className="space-y-2 text-sm">
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Leverage our AI to determine the perfect times
                                to send your messages for each target country.
                              </span>
                            </li>
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Increase your click-through rates (CTR) by
                                reaching your audience when they’re most likely
                                to engage.
                              </span>
                            </li>
                          </ul>
                        </p>
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>

                  <a
                    class="before:absolute before:inset-0 before:z-[9]"
                    href="#"
                  ></a>
                </div>
              </div>

              {/* <!-- Card --> */}
              <div id="cv" class="scroll-mt-12">
                <div class="relative p-0.5 bg-gradient-to-tl from-gray-100 via-transparent to-gray-100 rounded-xl overflow-hidden dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                  <div class="p-5 lg:p-8 bg-gradient-to-bl from-gray-200 via-white to-gray-50 rounded-xl dark:from-neutral-700 dark:via-neutral-900 dark:to-neutral-800">
                    {/* <!-- Grid --> */}
                    <div class="grid lg:grid-cols-2 lg:grid-cols-3 items-center gap-5">
                      <div class="lg:order-2 lg:col-span-1 lg:col-span-2">
                        <div class="grid grid-cols-2 sm:grid-cols-3 items-center gap-x-2 sm:gap-x-5">
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai2.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai1.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                          <div class="hs-tooltip inline-block">
                            <a
                              class="hs-tooltip-toggle relative z-10 block transition hover:-translate-y-1 focus:outline-none focus:-translate-y-1"
                              href="#"
                            >
                              <img
                                class="rounded-lg shadow-md"
                                src={require("../../assets/images/home_images/ai3.jpeg")}
                                alt="Main Page"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Col --> */}

                      <div class="lg:order-1 lg:col-span-1 lg:col-span-1 text-center lg:text-start">
                        <h3 class="text-xl lg:text-2xl font-semibold text-gray-800 dark:text-white">
                          Boost Your Productivity
                        </h3>
                        <p class="mt-1 text-sm md:text-base text-gray-600 dark:text-neutral-400">
                          <ul className="space-y-2 text-sm">
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Simplify your marketing processes and enhance
                                efficiency.
                              </span>
                            </li>
                            <li class="flex gap-x-3">
                              <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                                <svg
                                  class="shrink-0 size-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                              </span>
                              <span class="text-gray-600 dark:text-white">
                                Atz AI’s intelligent features optimize your
                                content and timing strategies, letting you focus
                                on achieving your business goals.
                              </span>
                            </li>
                          </ul>
                        </p>
                      </div>
                      {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Grid --> */}
                  </div>

                  <a
                    class="before:absolute before:inset-0 before:z-[9]"
                    href="#"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-[85rem] mx-auto px-4 pt-6 sm:px-6 lg:px-8">
          <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-2xl px-4 sm:px-6 py-12 md:py-20 lg:px-8 mx-auto">
              <div class="mb-5 sm:mb-10 text-center">
                <h2 class="text-2xl font-bold lg:text-3xl lg:leading-tight dark:text-white">
                  Get Started
                  <span class="text-[#019ca2] dark:text-[#019ca2]"> Today</span>
                </h2>
                <p class="mt-3 text-gray-500 dark:text-neutral-400">
                  <ul className="space-y-2 text-sm">
                    <li class="flex gap-x-3">
                      <span class="size-5 flex justify-center items-center rounded-full bg-teal-50 text-[#019ca2] dark:bg-teal-800/30 dark:text-[#019ca2]">
                        <svg
                          class="shrink-0 size-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </span>
                      <span class="text-gray-600 dark:text-white">
                        Transform your marketing efforts with Atz AI. Start
                        creating impactful content, optimizing delivery, and
                        boosting your productivity today!
                      </span>
                    </li>
                  </ul>
                </p>
              </div>

              {/* <!-- Buttons --> */}
              <div class="grid gap-3 justify-center w-full sm:inline-flex">
                <a
                  class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#1e8488] focus:bg-[#147f83] disabled:opacity-50 disabled:pointer-events-none"
                  href={url}
                >
                  Get started
                  <svg
                    className="p-0.5"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.7242 8.45192L7.28876 7.19383C6.55281 8.18469 5.92055 9.24884 5.40213 10.3696L3.7242 8.45192ZM1.24742 8.6585L4.54987 12.4327L4.07152 13.6286C3.92296 14 4.01003 14.4242 4.29289 14.7071L4.60651 15.0207C2.90342 16.9411 1.9627 18.9496 1.05132 21.6838C0.931539 22.0431 1.02506 22.4393 1.29289 22.7071C1.56073 22.9749 1.95689 23.0685 2.31623 22.9487C5.05037 22.0373 7.05889 21.0966 8.97928 19.3935L9.29289 19.7071C9.57576 19.99 9.99997 20.077 10.3714 19.9285L11.5673 19.4501L15.3415 22.7526C15.5911 22.971 15.9327 23.0514 16.2535 22.9673C16.5744 22.8832 16.8326 22.6456 16.943 22.3328L19.9291 13.8722C21.8977 11.5428 23 8.57479 23 5.48078V2C23 1.44772 22.5523 1 22 1H18.5192C15.4252 1 12.4572 2.10225 10.1278 4.0709L1.66718 7.05701C1.35444 7.16739 1.11676 7.42565 1.03268 7.74646C0.948589 8.06728 1.02903 8.40891 1.24742 8.6585ZM3.68527 20.3147C4.31277 18.7992 5.017 17.5929 6.02356 16.4378L7.56223 17.9764C6.40713 18.983 5.20083 19.6872 3.68527 20.3147ZM10.2408 17.8266L9.70711 17.2929L6.70711 14.2929L6.17337 13.7592L6.88327 11.9844C7.53465 10.356 8.44936 8.84567 9.59079 7.51401L10.1674 6.84129C12.2572 4.40319 15.308 3 18.5192 3H21V5.48078C21 8.69196 19.5968 11.7428 17.1587 13.8326L16.486 14.4092C15.1543 15.5506 13.644 16.4653 12.0156 17.1167L10.2408 17.8266ZM15.5481 20.2758L13.6304 18.5979C14.7512 18.0795 15.8153 17.4472 16.8062 16.7112L15.5481 20.2758ZM15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8ZM16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </a>
              </div>
              {/* <!-- End Buttons --> */}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AtzAi;
