import React from "react";
import { useState, useEffect } from "react";
import { DatePicker, Space, theme } from "antd";
const AntDatePicker = () => {
  const { token } = theme.useToken();
  const [currentTheme, setCurrentTheme] = useState("light");

  //   const theme = localStorage.getItem("theme");
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    setCurrentTheme(theme);
  }, []);
  //   const theme = localStorage.getItem("theme");
  const style = {
    border: `1px solid ${token.colorPrimary}`,
    borderRadius: "50%",
  };
  const cellRender = (current, info) => {
    if (info.type !== "date") {
      return info.originNode;
    }
    if (typeof current === "number" || typeof current === "string") {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div
        className="ant-picker-cell-inner"
        style={current.date() === 1 ? style : {}}
      >
        {console.log(current.date())}
        {current.date()}
      </div>
    );
  };
  return (
    <Space size={12} direction="vertical">
      <DatePicker cellRender={cellRender} />
    </Space>
  );
};
export default AntDatePicker;
